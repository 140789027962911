import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { useManagementTextField } from "../../../hooks/forms/ManagementHooks";
import { useLoadingToast } from "../../../hooks/toast/toasts";
import { generateAttorneyPdf, getProject } from "../../../services/api/api";
import { downloadFileFromApi } from "../../../services/pdf/download-file-from-api";
import FileManager from "../../fileManager/FileManager";
import useAddressForm from "../../forms/address/controller";
import CustomTextField from "../../textFields/CustomTextField";
import CommonEdit from "./CommonEdit";
import PaymentsHistory from "./PaymentsHistory";
import SimpleEdit from "./SimpleEdit";
import StagesHistory from "./StagesHistory";
import AttorneyForm from "./attorney/AttorneyForm";
import useAttorneyForm from "./attorney/controller";
import { EditProjectContextProvider } from "./context/EditProjectContext";

export default function EditProject() {
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();
  const { reset: resetProjectNumber, ...projectNumber } = useManagementTextField("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [projectData, setProjectData] = useState();
  const [disableAllFields, setDisableAllFields] = useState(false);
  const [clientValue, setClientValue] = useState("");
  const addressController = useAddressForm();
  const attorneyController = useAttorneyForm();

  const handleClientChange = async (event, newValue) => {
    setClientValue(newValue);
  };

  const clearAllFields = () => {
    resetProjectNumber();
    setProjectData(null);
  };

  const handleSearchProject = async (event) => {
    event.preventDefault();
    let searchToastId;
    try {
      clearAllFields();
      setSearchLoading(true);
      searchToastId = initializeLoadingToast({
        text: `Buscando Projeto...`,
      });

      const { data } = await getProject(projectNumber.value);

      setClientValue(data.clients);

      setProjectData(data);
      addressController.loadData({
        street: data.street,
        number: data.number,
        district: data.district,
        addressDetails: data.addressDetails,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode,
      });
      attorneyController.loadData(data);
      updateLoadingToast({
        id: searchToastId,
        text: `Projeto encontrado com sucesso`,
        type: "success",
      });
    } catch (error) {
      updateLoadingToast({
        id: searchToastId,
        text: error?.response?.data?.message ?? `Erro ao buscar Projeto, tente novamente`,
        type: "error",
      });
      clearAllFields();
    } finally {
      setSearchLoading(false);
      searchToastId = null;
    }
  };

  const client = {
    value: clientValue,
    onChange: handleClientChange,
  };

  const [isAttorneySending, setIsAttorneySending] = useState(false);

  const handleSendAttorney = async (event) => {
    event.stopPropagation();
    let sendAttorneyToastId;
    setIsAttorneySending(true);
    setDisableAllFields(true);
    try {
      sendAttorneyToastId = initializeLoadingToast({
        text: `Gerando Procuração...`,
      });

      const fileName = `Procuração - ${projectData.projectNumber} - ${projectData.name}.pdf`;
      const { status, data } = await generateAttorneyPdf(projectData.id);

      if (status === 200) {
        updateLoadingToast({
          id: sendAttorneyToastId,
          text: `Procuração gerada com sucesso`,
          type: "success",
        });

        downloadFileFromApi(data, fileName);
      }
    } catch (error) {
      updateLoadingToast({
        id: sendAttorneyToastId,
        text: error.message ?? `Erro ao gerar procuração, tente novamente`,
        type: "error",
      });
    } finally {
      setIsAttorneySending(false);
      setDisableAllFields(false);
    }
  };

  return (
    <EditProjectContextProvider>
      <Box sx={{ mt: 4 }}>
        <ToastContainer />

        <form
          onSubmit={handleSearchProject}
          style={{ display: "flex", width: "45%", justifyContent: "space-between" }}
        >
          <CustomTextField
            params={projectNumber}
            disabled={false}
            label="Insira o numero do Projeto"
            errorLabel="Numero Incorreto"
            maxLength={7}
            width="65%"
          />
          <Button
            variant="contained"
            sx={{ width: "25%", height: "100%" }}
            disabled={searchLoading}
            type="submit"
          >
            {searchLoading ? "Buscando..." : "Buscar"}
          </Button>
        </form>

        {projectData ? (
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8} display={"flex"} flexDirection={"column"} gap={1.5}>
              <SimpleEdit
                projectData={projectData}
                clearAllFields={clearAllFields}
                client={client}
                isDisabled={disableAllFields}
                setDisabled={setDisableAllFields}
                initializeLoadingToast={initializeLoadingToast}
                updateLoadingToast={updateLoadingToast}
                addressController={addressController}
                attorneyController={attorneyController}
              />
              <AttorneyForm
                initializeLoadingToast={initializeLoadingToast}
                updateLoadingToast={updateLoadingToast}
                projectId={projectData.id}
                setDisabled={setDisableAllFields}
                isDisabled={disableAllFields}
                controller={attorneyController}
                addressController={addressController}
                isEditing={true}
                isAttorneySending={isAttorneySending}
                handleSendAttorney={handleSendAttorney}
              />
              <CommonEdit
                projectData={projectData}
                isDisabled={disableAllFields}
                setDisabled={setDisableAllFields}
                initializeLoadingToast={initializeLoadingToast}
                updateLoadingToast={updateLoadingToast}
              />
            </Grid>
            <Grid item xs={12} lg={4} display={"flex"} flexDirection={"column"} gap={1.5}>
              <FileManager rootDirectory={`/${projectData.projectNumber}`} />
              <PaymentsHistory
                projectId={projectData.id}
                isUniqueCharge={!!projectData.clients.isUniqueCharge}
                clearAllFields={clearAllFields}
                isDisabled={disableAllFields}
                setDisabled={setDisableAllFields}
                initializeLoadingToast={initializeLoadingToast}
                updateLoadingToast={updateLoadingToast}
              />
              <StagesHistory projectId={projectData.id} />
            </Grid>
          </Grid>
        ) : null}
      </Box>
    </EditProjectContextProvider>
  );
}
