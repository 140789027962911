import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React from "react";
import {
    FormatMoneyTypography,
    FormatPercentageTypography,
} from "../../format/Formats";

export default function Costs({ data }) {
  const valuesString = {
    projects: { name: "Projetos", format: "none" },
    arts: { name: "ARTs", format: "none" },
    tax: { name: "Impostos", format: "percentage" },
    variableCosts: { name: "Custos Variáveis", format: "money" },
    fixedCosts: { name: "Custos Fixos", format: "money" },
    revenueTarget: { name: "Meta de Faturamento", format: "money" },
    failureRate: { name: "Índice de Reprova", format: "" },
    comissionPercentage: {
      name: "Porcentagem de Comissão",
      format: "percentage",
    },
  };

  const returnFormatedValue = (value, format) => {
    const sx = {
      fontFamily: "Red Hat Display",
      color: "black",
    };

    if (format === "none") {
      return <Typography sx={sx}>{value}</Typography>;
    } else if (format === "percentage") {
      return <FormatPercentageTypography value={value} sx={sx} />;
    }

    return <FormatMoneyTypography value={value} sx={sx} />;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      sx={{
        borderRadius: "0.625rem",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.3)",
      }}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        sx={{
          paddingX: 3.5,
          paddingY: 2,
          backgroundColor: "#EEEEEE",
          borderRadius: "0.625rem",
        }}
      >
        <Typography sx={{ fontSize: "1.25rem", fontWeight: 600 }}>
          Custos
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        sx={{ p: 4 }}
      >
        {Object.entries(data).map((entry, index) => {
          return (
            <React.Fragment>
              <Box
                key={entry[0]}
                display="flex"
                flexDirection="row"
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignContent: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.125rem",
                    fontFamily: "Red Hat Display",
                    fontWeight: 500,
                    maxWidth: "45%",
                  }}
                >
                  {valuesString[entry[0]].name}
                </Typography>
                {returnFormatedValue(entry[1], valuesString[entry[0]].format)}
              </Box>
              <Divider orientation="horizontal" sx={{ mb: 0.8 }} />
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
}
