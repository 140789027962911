import { useContext } from "react";

import { ApiDataContext } from "../../../contexts/ApiDataContext";
import { useAutocomplete } from "../../../hooks/forms/FormHooks";
import { useManagementTextField } from "../../../hooks/forms/ManagementHooks";

import StagesAutoComplete from "../../autoCompletes/StagesAutoComplete";
import CustomTextField from "../../textFields/CustomTextField";

import Box from "@mui/material/Box";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import { useDatePicker } from "../../../hooks/forms/useDatePicker";
import { useLoadingToast } from "../../../hooks/toast/toasts";
import { createProject, getNextProjectNumber, getProjectPrice } from "../../../services/api/api";
import DatePicker from "../../DatePicker";
import CustomAutoComplete from "../../autoCompletes/CustomAutoComplete";
import PowerDistributionCompaniesAutoComplete from "../../autoCompletes/PowerDistributionCompaniesAutoComplete";
import ProvidencesAutoComplete from "../../autoCompletes/ProvidencesAutoComplete";
import CustomLoadingButton from "../../button/CustomLoadingButton";
import { FormatMoney, FormatPower } from "../../format/Formats";
import useAddressForm from "../../forms/address/controller";
import FormatedCustomTextField from "../../textFields/FormatedCustomTextField";
import AttorneyForm from "./attorney/AttorneyForm";
import useAttorneyForm from "./attorney/controller";

export default function CreateProject() {
  const { apiData } = useContext(ApiDataContext);
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  const [clientValue, setClientValue] = useState("");
  const [clientsPDCs, setClientsPDCs] = useState([]);
  const [powerValue, setPowerValue] = useState("");
  const [projectPrice, setProjectPrice] = useState("");

  const [nextProjectNumber, setNextProjectNumber] = useState("");

  const handleClientChange = async (event, newValue) => {
    setPowerValue("");
    setProjectPrice("");
    setClientValue(newValue);
    setClientsPDCs(newValue.clientsPDC);

    const { data } = await getNextProjectNumber(newValue.internalClient);
    setNextProjectNumber(data);
  };

  const client = {
    value: clientValue,
    onChange: handleClientChange,
  };

  const resetClientValue = () => {
    setClientValue("");
  };

  const handlePowerChange = async (event) => {
    const _power = event.target.value;
    setPowerValue(_power);
    const isInternalClient = clientValue.internalClient === 1;
    const { data } = await getProjectPrice({
      power: _power,
      isInternalClient,
      tableId: clientValue.priceTableId,
    });
    setProjectPrice(data);
  };

  const power = {
    value: powerValue,
    onChange: handlePowerChange,
  };

  const resetPowerValue = () => {
    setPowerValue("");
  };

  const { reset: resetProjectNumber, ...projectNumber } = useManagementTextField(nextProjectNumber);
  const { reset: resetProjectName, ...projectName } = useManagementTextField("");
  const { reset: resetStage, ...stage } = useAutocomplete("", false);
  const { reset: resetProvidence, ...providence } = useAutocomplete("", false);
  const { reset: resetDeadline, ...deadline } = useDatePicker(null);
  const { reset: resetPDC, ...powerDistributionCompany } = useAutocomplete("", false);
  const { reset: resetEngineer, ...engineer } = useAutocomplete("", false);
  const { reset: resetPrice, ...price } = useManagementTextField(projectPrice);
  const { reset: resetNote, ...note } = useManagementTextField("");

  const addressController = useAddressForm();
  const attorneyController = useAttorneyForm();

  const isDisabled = clientValue === "";

  const clearAllFields = () => {
    resetClientValue();
    resetProjectNumber();
    resetProjectName();
    resetStage();
    resetProvidence();
    resetDeadline();
    resetPDC();
    resetEngineer();
    resetPowerValue();
    resetPrice();
    resetNote();
    addressController.clearAllFields();
    attorneyController.clearAllFields();
    setNextProjectNumber("");
  };

  const queryClient = useQueryClient();
  const [requestLoading, setRequestLoading] = useState(false);

  const handleCreateProject = async () => {
    let createToastId;
    try {
      setRequestLoading(true);
      createToastId = initializeLoadingToast({
        text: `Criando Projeto...`,
      });

      const attorneyValues = attorneyController.values();

      const npParams = {
        cpf: attorneyValues.cpf,
        rg: attorneyValues.rg,
        issuingBody: attorneyValues.issuingBody,
      };

      const leParams = {
        cnpj: attorneyValues.cnpj,
        repName: attorneyValues.repName,
        repNationality: attorneyValues.repNationality,
        repCpf: attorneyValues.cpf,
        repRg: attorneyValues.rg,
        repIssuingBody: attorneyValues.issuingBody,
      };

      const newProject = await createProject({
        clientId: client.value.id,
        internalClient: client.value.internalClient,
        projectNumber: projectNumber.value,
        projectName: projectName.value,
        stageId: stage.value.id,
        providenceId: providence.value.id,
        deadline: deadline.value,
        powerDistributionCompanyId: powerDistributionCompany.value.powerDistributionCompanyId,
        engineerId: engineer.value.id,
        power: power.value,
        price: price.value,
        note: note.value,
        clientType: attorneyValues.clientType,
        npParams,
        leParams,
        ...addressController.values(),
      });

      if (newProject.status === 201) {
        clearAllFields();
        queryClient.invalidateQueries({ queryKey: ["userProvidences"] });
        queryClient.invalidateQueries({ queryKey: ["homeBaseQuery"] });
        queryClient.invalidateQueries({ queryKey: ["filteredProjectsQuery"] });

        updateLoadingToast({
          id: createToastId,
          text: `Projeto Criado com sucesso`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: createToastId,
        text: error.response.data.message ?? "Erro ao criar Projeto, tente novamente",
        type: "error",
      });
    } finally {
      setRequestLoading(false);
    }
  };

  const buttonStyle = {
    mt: 3,
    alignSelf: "flex-end",
    width: "20%",
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        mt: 4,
      }}
    >
      <ToastContainer />

      <CustomAutoComplete
        params={client}
        options={apiData.clients}
        disabled={false}
        label="Selecione o Integrador"
        width="40%"
      />
      {!clientValue ? null : (
        <Box
          sx={{
            mt: 4,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            p: 2,
            justifyContent: "space-between",
            borderRadius: 3,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "85%",
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Box
              sx={{
                width: "45%",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <CustomTextField
                params={projectNumber}
                disabled={true}
                label="Numero do Projeto"
                errorLabel="Numero Incorreto"
                maxLength={7}
                margin={0}
              />
              <CustomTextField
                params={projectName}
                disabled={isDisabled}
                label="Nome do Cliente"
                errorLabel="Nome Incorreto"
                maxLength={50}
                margin={0}
              />
              <StagesAutoComplete
                disabled={isDisabled}
                params={stage}
                options={apiData.stages}
                label="Etapa"
                width="100%"
              />
              <ProvidencesAutoComplete
                disabled={isDisabled}
                params={providence}
                options={apiData.providences}
                label="Providência"
                width="100%"
              />

              <DatePicker
                disabled={isDisabled}
                width="100%"
                label="Prazo Máximo"
                params={deadline}
              />
            </Box>
            <Box
              sx={{
                width: "45%",
                display: "flex",
                flexDirection: "column",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <PowerDistributionCompaniesAutoComplete
                params={powerDistributionCompany}
                options={clientsPDCs}
                disabled={isDisabled}
                label="Concessionária"
                width="100%"
              />
              <CustomAutoComplete
                params={engineer}
                options={apiData.engineers}
                disabled={isDisabled}
                label="Engenheiro"
                width="100%"
                margin={0}
                pt={0}
              />
              <FormatedCustomTextField
                params={power}
                disabled={isDisabled}
                label="Potência"
                errorLabel="Potência Incorreta"
                inputcomponent={FormatPower}
                required={false}
                width="100%"
                margin={0}
              />

              <FormatedCustomTextField
                params={price}
                disabled={isDisabled || clientValue.internalClient === 1 || power.value === ""}
                label="Valor"
                errorLabel="Valor Incorreto"
                inputcomponent={FormatMoney}
                required={false}
                width="100%"
                margin={0}
              />

              <CustomTextField
                params={note}
                disabled={isDisabled}
                label="Observação"
                errorLabel="Observação Incorreta"
                maxLength={200}
                required={false}
              />
            </Box>
          </Box>

          <AttorneyForm
            isDisabled={requestLoading}
            controller={attorneyController}
            addressController={addressController}
          />

          <CustomLoadingButton
            buttonText="Cadastrar"
            buttonLoadingText="Cadastrando..."
            sx={buttonStyle}
            disabled={requestLoading}
            handleButtonClick={handleCreateProject}
            requestLoading={requestLoading}
          />
        </Box>
      )}
    </Box>
  );
}
