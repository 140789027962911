import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import MultipleAutocomplete from "components/autoCompletes/MultipleAutocomplete";
import CustomDateRangePicker from "../../CustomDateRangePicker";
import CustomAutoComplete from "../../autoCompletes/CustomAutoComplete";

export default function FiltersDialog({
  open,
  onClose,
  client,
  clientsOptions,
  stage,
  stageOptions,
  isStageChecked,
  range,
  handleRangeChange,
  handleApply,
  handleClearFilters,
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={handleClearFilters}>
            <FilterAltOffIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <CustomAutoComplete
            params={client}
            options={clientsOptions}
            disabled={false}
            label="Selecione o Integrador"
            width="100%"
          />
          <MultipleAutocomplete
            params={stage}
            values={stageOptions}
            valueKey="stageName"
            isChecked={isStageChecked}
            disabled={false}
            label="Selecione a Etapa"
            width="100%"
            margin={2}
            sx={{ pt: 1 }}
          />
        </Box>

        <CustomDateRangePicker ranges={range} onChange={handleRangeChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleApply} variant="contained" color="primary">
          Aplicar Filtros
        </Button>
      </DialogActions>
    </Dialog>
  );
}
