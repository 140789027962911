import { useQuery } from "react-query";
import { getFinancesProjects, getFinancesSummary } from "../../services/api/api";

const fetchFinancesSummary = async (filters) => {
  const { data } = await getFinancesSummary(filters);
  return data;
};

const fetchFinancesProjects = async (filters) => {
  const { data } = await getFinancesProjects(filters);
  return data;
};

export const useFinancesSummary = (filters) => {
  return useQuery(["financesSummary", filters], () => fetchFinancesSummary(filters));
};

export const useFinancesProjects = (filters, { page = 0, size = 10 }) => {
  return useQuery(
    ["financesProjects", filters, page, size],
    () => fetchFinancesProjects({ ...filters, page, size }),
    {
      keepPreviousData: true,
    }
  );
};
