import { api } from "../api";

export const createDefaultFolders = async (projectNumber) => {
  const url = `/files/create/${encodeURIComponent(projectNumber)}?default=true`;
  return await api.post(url);
};

export const createFolder = async (directoryPath) => {
  return await api.post(`/files/create/${encodeURIComponent(directoryPath)}`);
};

// Função para listar arquivos em um diretório específico no OneDrive
export const listFiles = async (directoryPath) => {
  try {
    const response = await api.get(`/files/path/${encodeURIComponent(directoryPath)}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao listar arquivos:", error);
    throw error;
  }
};
// Função para listar arquivos em um diretório específico no OneDrive pelo ID
export const listFilesById = async (id) => {
  try {
    const response = await api.get(`/files/id/${encodeURIComponent(id)}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao listar arquivos:", error);
    throw error;
  }
};
export const getFilePreviewLink = async (id) => {
  try {
    const response = await api.get(`/files/preview?id=${encodeURIComponent(id)}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao listar arquivos:", error);
    throw error;
  }
};

export const uploadFile = async (directoryPath, fileName, file) => {
  const formData = new FormData();
  formData.append("directoryPath", directoryPath);
  formData.append("fileName", fileName);
  formData.append("fileContent", file); // This should match 'fileContent' used in multer

  try {
    const response = await api.post("/files", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao fazer upload para:", error);
    throw error;
  }
};

// Função para baixar um arquivo do OneDrive
export const downloadFile = async (fileId) => {
  try {
    const response = await api.get(`/files/download/${fileId}`, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao baixar arquivo :", error);
    throw error;
  }
};

// Função para deletar um arquivo do OneDrive
export const deleteFile = async (fileId) => {
  return await api.delete(`/files/${fileId}`);
};
