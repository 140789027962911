import { api } from "../api";

// Filters:
// startDate: Date
// endDate: Date
// clientIds: number[]
export const getPayments = async (filters) => {
  let url = "/charges";
  return await api.get(url, {
    params: filters,
  });
};

export const getSummary = async (filters) => {
  let url = "/charges/summary";
  return await api.get(url, {
    params: filters,
  });
};

export const getPaymentsHistory = async (projectId) => {
  return await api.get(`/charges/history/${projectId}`);
};

export const markAsPaid = async (id) => {
  let url = `/charges/markAsPaid/${id}`;
  return await api.put(url);
};

export const getChargesReport = async (filters) => {
  let url = `/charges/report`;

  return await api.get(url, {
    params: filters,
    responseType: "blob",
  });
};
