import GridOnIcon from "@mui/icons-material/GridOn";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSummary } from "../../hooks/api/chargesHooks";
import { getChargesReport } from "../../services/api/api";
import CustomLoadingButton from "../button/CustomLoadingButton";
import Filters from "./charges/Filters";
import Payments from "./charges/Payments";
import Summary from "./shared/Summary";

const validateStatus = (status) => {
  if (!status) return "";
  const VALID_STATUSES = ["PAID", "MARKED_AS_PAID", "WAITING", "OVERDUE", "EXPIRED"];
  if (VALID_STATUSES.includes(status)) {
    return status;
  }
  return "";
};

const validateClientId = (clientId) => {
  if (!clientId) return "";
  const id = parseInt(clientId, 10);
  if (Number.isInteger(id) && id > 0) {
    return id;
  }
  return "";
};

const validateSearchParams = (searchParams) => {
  const status = validateStatus(searchParams.get("status"));
  const clientId = validateClientId(searchParams.get("clientId"));
  // const startDate = searchParams.get("startDate") || subDays(new Date(), 30).toISOString();
  const startDate = searchParams.get("startDate") || "";
  // const endDate = searchParams.get("endDate") || new Date().toISOString().split("T")[0];
  const endDate = searchParams.get("endDate") || "";
  const search = searchParams.get("search") || "";
  return {
    status,
    clientId,
    startDate,
    endDate,
    search,
  };
};

export default function Charges() {
  const [searchParams, setSearchParams] = useSearchParams();
  const validSearchParams = validateSearchParams(searchParams);
  const [filters, setFilters] = useState(validSearchParams);
  const [isXlsxloading, setXlsxLoading] = useState(false);

  useEffect(() => {
    setSearchParams(filters);
  }, [filters, setSearchParams]);

  const applyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const clearFilter = (filterKey) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: "",
    }));
  };

  const memoizedFilters = useMemo(() => filters, [filters]);
  const { data: summaryData, isLoading: summaryDataLoading } = useSummary(memoizedFilters);

  const handleXlsxClick = async () => {
    try {
      setXlsxLoading(true);

      const { status, data } = await getChargesReport(memoizedFilters);

      if (status === 200) {
        const blobUrl = URL.createObjectURL(data);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = "relatorio_cobrancas.xlsx";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setXlsxLoading(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            minHeight: 600,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mb: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              Gestão de Cobranças
            </Typography>

            <CustomLoadingButton
              startIcon={<GridOnIcon />}
              buttonText="Exportar Planilha"
              buttonLoadingText="Exportando..."
              sx={{ width: "fit-content", height: "70%" }}
              disabled={isXlsxloading}
              handleButtonClick={handleXlsxClick}
              requestLoading={isXlsxloading}
            />
          </Box>
          <Divider sx={{ mb: 2 }} />

          <Filters filters={filters} applyFilters={applyFilters} clearFilter={clearFilter} />
          <Summary isLoading={summaryDataLoading} data={summaryData} />
          <Payments filters={filters} />
        </Paper>
      </Grid>
    </Grid>
  );
}
