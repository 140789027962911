import Button from "@mui/material/Button";
import { useTheme } from "@mui/system";

type Props = {
  handleButtonClick: any;
  buttonText: string;
  sx?: any;
  disabled?: boolean;
  isPrimary?: boolean;
  startIcon?: any;
  endIcon?: any;
};

export default function CustomButton({
  handleButtonClick,
  buttonText,
  sx,
  disabled,
  isPrimary = false,
  startIcon,
  endIcon,
}: Props) {
  const theme = useTheme();

  let buttonSx;
  let bgColor;
  let bgHoverColor;
  if (isPrimary) {
    bgColor = theme.palette.primary.main;
    bgHoverColor = theme.palette.primary.hover;
  } else {
    bgColor = theme.palette.secondary.main;
    bgHoverColor = theme.palette.secondary.hover;
  }

  buttonSx = {
    ...sx,
    backgroundColor: bgColor,
    "&:hover": {
      backgroundColor: bgHoverColor,
    },
  };

  return (
    <Button
      variant="contained"
      sx={buttonSx}
      disabled={disabled}
      onClick={handleButtonClick}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {buttonText}
    </Button>
  );
}
