import { Button, styled, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useManagementTextField } from "../../../../../hooks/forms/ManagementHooks";
import CustomButton from "../../../../button/CustomButton";
import CustomLoadingButton from "../../../../button/CustomLoadingButton";
import CustomTextField from "../../../../textFields/CustomTextField";

const BodyCell = styled(TableCell)({
  fontSize: "0.9rem",
  width: "27%",
});

const ActionCell = styled(TableCell)({
  width: "25%",
});

export default function PricesRow({
  row,
  editingId,
  isEditing,
  handleEdit,
  handleCancel,
  handleSave,
}) {
  const { reset: resetPrice, ...price } = useManagementTextField(row.price);
  const isSelected = editingId === row.id;

  if (isEditing && isSelected) {
    return (
      <TableRow
        key={row.id}
        hover
        selected={isSelected}
        onClick={() => handleCancel()}
        sx={{ cursor: "pointer" }}
      >
        <BodyCell>{row.initialIndex ?? 0} kWh</BodyCell>
        <BodyCell>{row.finalIndex ?? 0} kWh</BodyCell>
        <BodyCell onClick={(e) => e.stopPropagation()}>
          <CustomTextField margin={0} required={true} params={price} />
        </BodyCell>

        <ActionCell>
          <Button
            color="error"
            onClick={() => {
              resetPrice();
              handleCancel();
            }}
          >
            Cancelar
          </Button>
          <CustomLoadingButton
            buttonText="Salvar"
            buttonLoadingText="Salvando..."
            handleButtonClick={() => handleSave(row.id, Number(price.value))}
          />
        </ActionCell>
      </TableRow>
    );
  }

  return (
    <React.Fragment>
      <TableRow key={row.id} hover onClick={() => handleEdit(row.id)} sx={{ cursor: "pointer" }}>
        <BodyCell>{row.initialIndex ?? 0} kWh</BodyCell>
        <BodyCell>{row.finalIndex ?? 0} kWh</BodyCell>
        <BodyCell>R$ {price.value ?? 0}</BodyCell>
        <ActionCell>
          <CustomButton buttonText="Alterar" handleButtonClick={() => handleEdit(row.id)} />
        </ActionCell>
      </TableRow>
    </React.Fragment>
  );
}
