import { createContext, useContext, useState } from "react";
import { IEditProjectContext } from "./interface";

export type AccordionsState = {
  simpleEditOpen: boolean;
  attorneyDataOpen: boolean;
  commonEditOpen: boolean;
  fileManagerOpen: boolean;
  paymentOpen: boolean;
  stagesOpen: boolean;
};

const getItem = (key: keyof AccordionsState): boolean => {
  const state = JSON.parse(localStorage.getItem("accordionsState") ?? "{}");
  if (!state) return false;
  return state[key] === true || state[key] === "true";
};

export const EditProjectContext = createContext({} as IEditProjectContext);

export const useEditProjectContext = () => useContext(EditProjectContext);

export const EditProjectContextProvider = ({ children }) => {
  const [accordionsState, setAccordionsState] = useState<AccordionsState>({
    simpleEditOpen: getItem("simpleEditOpen"),
    attorneyDataOpen: getItem("attorneyDataOpen"),
    commonEditOpen: getItem("commonEditOpen"),
    fileManagerOpen: getItem("fileManagerOpen"),
    paymentOpen: getItem("paymentOpen"),
    stagesOpen: getItem("stagesOpen"),
  });

  const updateAccordionState = (key: keyof AccordionsState, open: boolean) => {
    setAccordionsState((prevState) => ({ ...prevState, [`${key}`]: open }));
    const currentLocalStorageState = JSON.parse(localStorage.getItem("accordionsState") ?? "{}");
    const newLocalStorageState = { ...currentLocalStorageState, [key]: open };
    localStorage.setItem("accordionsState", JSON.stringify(newLocalStorageState));
  };

  return (
    <EditProjectContext.Provider
      value={{
        accordionsState,
        updateAccordionState,
      }}
    >
      {children}
    </EditProjectContext.Provider>
  );
};
