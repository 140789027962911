import { Box, CircularProgress, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { useFetchStagesHistory } from "../../../hooks/api/stagesHistoryApiHooks";
import { formatDateHour } from "../../../services/date";
import CustomAccordion from "../../shared/CustomAccordion";
import { useEditProjectContext } from "./context/EditProjectContext";

const StagesHistory = ({ projectId }) => {
  const { stagesHistoryLoading, stagesHistoryData } = useFetchStagesHistory(projectId);
  const { accordionsState, updateAccordionState } = useEditProjectContext();
  const handleChange = (e, isExpanded) => {
    updateAccordionState("stagesOpen", isExpanded);
  };

  return (
    <CustomAccordion
      expanded={accordionsState.stagesOpen}
      onChange={handleChange}
      height={420}
      title="Histórico de Etapas"
    >
      <List
        sx={{
          overflow: "auto",
        }}
      >
        {stagesHistoryLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ minHeight: 600 }}>
            {stagesHistoryData.map((stage, index) => {
              const parsedInitialDate = formatDateHour(stage.initialDate);

              let parsedFinalDate = "";
              if (stage.finalDate != null) {
                parsedFinalDate = formatDateHour(stage.finalDate);
              }

              return (
                <ListItem
                  key={index}
                  sx={{
                    p: 2,
                    border: "1px solid black",
                    borderRadius: 2,
                    mb: 2,
                  }}
                >
                  <ListItemText
                    primary={stage.stages.stageName}
                    secondary={
                      <React.Fragment>
                        <Typography>
                          {parsedInitialDate} --- {parsedFinalDate}
                        </Typography>
                        <Typography>{stage.note}</Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              );
            })}
          </Box>
        )}
      </List>
    </CustomAccordion>
  );
};

export default StagesHistory;
