import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";

export default function FormatedCustomTextField({
  params,
  disabled,
  label,
  inputcomponent,
  required,
  width,
  variant = "outlined",
  style = null,
  margin = 2,
}) {
  const { value, onChange, error, errorLabel } = params;

  return (
    <Box width={width} sx={{ mb: margin }}>
      <TextField
        fullWidth
        value={value}
        onChange={onChange}
        required={required}
        label={label}
        disabled={disabled}
        variant={variant}
        InputProps={{
          inputComponent: inputcomponent,
          style: style,
        }}
      />
      <Typography color="error">{error ? errorLabel : ""}</Typography>
    </Box>
  );
}
