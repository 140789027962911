const initialState = {
  currentIndex: 0,
};

function meetingCarouselReducer(state = initialState, action) {
  switch (action.type) {
    case "NEXT_PROJECT":
      return { ...state, currentIndex: state.currentIndex + 1 };
    case "PREVIOUS_PROJECT":
      return { ...state, currentIndex: Math.max(state.currentIndex - 1, 0) };
    case "SET_PROJECT":
      return { ...state, currentIndex: action.payload };
    default:
      return state;
  }
}

export default meetingCarouselReducer;
