import { useTheme } from "@emotion/react";
import { Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { ApiDataContext } from "../../../contexts/ApiDataContext";
import useDialog from "../../../hooks/dialog/useDialog";
import { useFormField } from "../../../hooks/forms/FormHooks";
import { useManagementTextField } from "../../../hooks/forms/ManagementHooks";
import { deleteProject, getProjectPrice, simpleUpdateProject } from "../../../services/api/api";
import CustomAutoComplete from "../../autoCompletes/CustomAutoComplete";
import CustomLoadingButton from "../../button/CustomLoadingButton";
import CustomDialog from "../../dialog/CustomDialog";
import { FormatMoney, FormatPower } from "../../format/Formats";
import CustomTextField from "../../textFields/CustomTextField";
import FormatedCustomTextField from "../../textFields/FormatedCustomTextField";
import CustomAccordion from "../../shared/CustomAccordion";
import { useEditProjectContext } from "./context/EditProjectContext";

export default function SimpleEdit({
  projectData,
  clearAllFields,
  client,
  isDisabled,
  setDisabled,
  initializeLoadingToast,
  updateLoadingToast,
}) {
  const { apiData } = useContext(ApiDataContext);
  const queryClient = useQueryClient();

  const [requestLoading, setRequestLoading] = useState(false);

  const handleUpdateClick = async () => {
    let updateToastId;

    try {
      setRequestLoading(true);
      setDisabled(true);
      updateToastId = initializeLoadingToast({
        text: `Atualizando Informações Básicas...`,
      });

      const { status } = await simpleUpdateProject(projectData.id, {
        projectName: projectName.value,
        clientId: client.value.id,
        powerDistributionCompanyId: powerDistributionCompany.value.id,
        engineerId: engineer?.value?.id,
        power: power.value,
        price: price.value,
      });

      if (status === 200) {
        updateLoadingToast({
          id: updateToastId,
          text: `Informações Básicas Atualizadas com sucesso`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: updateToastId,
        text:
          error?.response?.data?.message ??
          `Erro ao atualizar Informações Básicas, tente novamente`,
        type: "error",
      });
    } finally {
      setRequestLoading(false);
      setDisabled(false);
    }
  };

  const { reset: resetProjectName, ...projectName } = useManagementTextField(projectData?.name);

  const { reset: resetPDC, ...powerDistributionCompany } = useFormField(
    "autocompleteChangeInitialValue",
    projectData?.powerDistributionCompanies,
    false
  );

  const { reset: reset, ...engineer } = useFormField(
    "autocompleteChangeInitialValue",
    projectData?.engineers,
    false
  );

  const [powerValue, setPowerValue] = useState(projectData?.power ?? "");
  const [projectPrice, setProjectPrice] = useState(projectData?.price ?? "");

  const handlePowerChange = async (event) => {
    const _power = event.target.value;
    setPowerValue(_power);
    const clientValue = client.value;
    const isInternalClient = clientValue.internalClient === 1;
    const { data } = await getProjectPrice({
      power: _power,
      isInternalClient,
      tableId: clientValue.priceTableId,
    });
    setProjectPrice(data);
  };

  const power = {
    value: powerValue,
    onChange: handlePowerChange,
  };

  const { reset: resetPrice, ...price } = useManagementTextField(projectPrice);

  const [deleteRequestLoading, setDeleteRequestLoading] = useState(false);

  const handleDeleteClick = async () => {
    let deleteToastId;
    setDeleteRequestLoading(true);
    setDisabled(true);
    try {
      deleteToastId = initializeLoadingToast({
        text: `Excluindo Projeto...`,
      });

      const { status } = await deleteProject(projectData.id);

      if (status === 200) {
        clearAllFields();
        queryClient.invalidateQueries({ queryKey: ["userProvidences"] });
        queryClient.invalidateQueries({ queryKey: ["homeBaseQuery"] });
        queryClient.invalidateQueries({ queryKey: ["filteredProjectsQuery"] });

        updateLoadingToast({
          id: deleteToastId,
          text: `Projeto Excluído com sucesso`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: deleteToastId,
        text: error.response.data.message ?? `Erro ao Excluir Projeto, tente novamente`,
        type: "error",
      });
    } finally {
      setDeleteRequestLoading(false);
      setDisabled(false);
      handleClickClose();
    }
  };

  const { open, handleClickOpen, handleClickClose } = useDialog();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { accordionsState, updateAccordionState } = useEditProjectContext();
  const handleChange = (e, isExpanded) => {
    updateAccordionState("simpleEditOpen", isExpanded);
  };

  return (
    <CustomAccordion
      expanded={accordionsState.simpleEditOpen}
      onChange={handleChange}
      title="Informações Básicas"
      actions={
        <Box
          display="flex"
          width="50%"
          sx={{
            alignSelf: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{
              width: "45%",
            }}
            disabled={isDisabled}
            onClick={handleClickOpen}
          >
            Excluir
          </Button>

          <CustomLoadingButton
            buttonText="Atualizar"
            buttonLoadingText="Atualizando..."
            sx={{ width: "45%" }}
            disabled={isDisabled}
            handleButtonClick={handleUpdateClick}
            requestLoading={requestLoading}
          />
        </Box>
      }
    >
      <CustomDialog
        open={open}
        handleClickClose={handleClickClose}
        title="Excluir Projeto"
        contentText="As informações do projeto serão excluídas e não poderão ser recuperadas."
        requestLoading={deleteRequestLoading}
        handleConfirmationClick={handleDeleteClick}
        confirmationClickText="Excluir"
        requestLoadingText="Excluindo..."
      />

      {projectData ? (
        <Box
          sx={{
            width: "100%",
            borderRadius: 3,
            display: "flex",
            mt: 1,
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              color: 102030,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: isSmallScreen ? "column" : "row",
            }}
          >
            <Box
              sx={{
                width: isSmallScreen ? "100%" : "48%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <CustomTextField
                params={projectName}
                disabled={isDisabled}
                label="Nome do Cliente"
                errorLabel="Nome Incorreto"
                maxLength={50}
              />
              <CustomAutoComplete
                disabled={isDisabled}
                params={client}
                options={apiData.clients}
                label="Integrador"
                width="100%"
              />
              <CustomAutoComplete
                disabled={isDisabled}
                params={powerDistributionCompany}
                options={apiData.powerDistributionCompanies}
                label="Concessionária"
                width="100%"
              />
            </Box>
            <Box
              sx={{
                width: isSmallScreen ? "100%" : "48%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <CustomAutoComplete
                disabled={isDisabled}
                params={engineer}
                options={apiData.engineers}
                label="Engenheiro"
                width="100%"
              />
              <FormatedCustomTextField
                params={power}
                disabled={isDisabled}
                label="Potência"
                errorLabel="Potência Incorreta"
                inputcomponent={FormatPower}
                required={false}
              />
              <FormatedCustomTextField
                params={price}
                disabled={isDisabled || client.internalClient === 1 || power.value === ""}
                label="Valor"
                errorLabel="Valor Incorreto"
                inputcomponent={FormatMoney}
                required={false}
              />
            </Box>
          </Box>
        </Box>
      ) : null}
    </CustomAccordion>
  );
}
