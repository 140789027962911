import { Box, Typography } from "@mui/material";
import { FormatMoneyTypography } from "../../format/Formats";

export default function SummaryComponent({ data, width }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", width, justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            width: 12,
            height: 12,
            mr: 1,
            borderRadius: 20,
            backgroundColor: data.color ?? "#0094dd",
          }}
        />

        <Typography>{data.name}</Typography>
      </Box>
      <FormatMoneyTypography value={data.value ?? 0} sx={{ fontSize: "1.125rem", fontWeight: "bold" }} />
    </Box>
  );
}
