import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box, Button, Chip } from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { ApiDataContext } from "../../../contexts/ApiDataContext";
import useDialog from "../../../hooks/dialog/useDialog";
import { useAutocomplete } from "../../../hooks/forms/FormHooks";
import { createDateFromString, formatDate } from "../../../services/date";
import { changePage } from "../../../store/ducks/payment-page-reducer";
import SearchInput from "../../textFields/SearchInput";
import FiltersDialog from "./FiltersDialog";

export default function Filters({ filters, applyFilters, clearFilter }) {
  const { handleClickOpen, handleClickClose, open } = useDialog();
  const { apiData } = useContext(ApiDataContext);
  const dispatch = useDispatch();

  const restartPage = () => {
    dispatch(changePage(0));
  };

  const [range, setRange] = useState([
    {
      startDate: filters.startDate ? createDateFromString(filters.startDate) : new Date(),
      endDate: filters.endDate ? createDateFromString(filters.endDate) : new Date(),
      key: "selection",
    },
  ]);

  const handleRangeChange = (item) => {
    setRange([item.selection]);
  };

  const clearRange = () => {
    setRange([
      {
        startDate: "",
        endDate: "",
        key: "selection",
      },
    ]);
  };

  const clearStartDate = () => {
    setRange((prevRange) => [
      {
        ...prevRange[0],
        startDate: "", // Clear the start date
      },
    ]);
  };

  const clearEndDate = () => {
    setRange((prevRange) => [
      {
        ...prevRange[0],
        endDate: "", // Clear the end date
      },
    ]);
  };

  const [selectedClient, setSelectedClient] = useState(
    filters.clientId
      ? apiData.clients.find((client) => client.id === parseInt(filters.clientId))
      : ""
  );

  const handleClientChange = async (event, newValue) => {
    setSelectedClient(newValue);
  };

  const clearClient = () => {
    setSelectedClient("");
  };

  const client = {
    value: selectedClient,
    onChange: handleClientChange,
  };

  const [search, setSearch] = useState(filters.searchStr ?? "");
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const statuses = [
    { id: "PAID", name: "Recebido" },
    { id: "MARKED_AS_PAID", name: "Marcado c/ recebido" },
    { id: "WAITING", name: "A receber" },
    { id: "OVERDUE", name: "Atrasado" },
    { id: "EXPIRED", name: "Expirado" },
    { id: "CANCELLED", name: "Cancelado" },
  ];

  const status = useAutocomplete(
    filters.status ? statuses.find((status) => status.id === filters.status) : ""
  );

  const clearStatus = () => {
    status.onChange("", "");
  };

  const handleApply = () => {
    applyFilters({
      status: status.value ? status.value.id : "",
      clientId: client.value ? client.value.id : "",
      startDate: range[0]?.startDate ? format(range[0].startDate, "yyyy-MM-dd") : "",
      endDate: range[0]?.endDate ? format(range[0].endDate, "yyyy-MM-dd") : "",
      search: search ?? "",
    });
    restartPage();
    handleClickClose();
  };

  const handleClearFilters = () => {
    restartPage();
    clearClient();
    clearRange();
    clearStatus();
    clearFilter("clientId");
    clearFilter("startDate");
    clearFilter("endDate");
    clearFilter("status");
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection={"column"}
        width="100%"
        justifyContent={"space-between"}
        gap={1}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 2,
            justifyContent: "space-between",
          }}
        >
          <SearchInput
            value={search}
            onChange={handleSearchChange}
            placeholder="Pesquise por nome ou número"
            onSubmit={handleApply}
            onClear={() => {
              setSearch("");
              clearFilter("search");
            }}
          />

          <Button
            sx={{ width: "15%", borderRadius: 10 }}
            variant="contained"
            endIcon={<FilterAltIcon />}
            onClick={handleClickOpen}
          >
            Filtros
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          {/* Show active filters */}
          {filters.clientId && (
            <Chip
              label={`Cliente: ${client?.value?.name ?? ""}`}
              onDelete={() => {
                clearClient();
                restartPage();
                clearFilter("clientId");
              }}
            />
          )}
          {filters.status && (
            <Chip
              label={`Status: ${status?.value?.name ?? ""}`}
              onDelete={() => {
                clearStatus();
                restartPage();
                clearFilter("status");
              }}
            />
          )}
          {filters.startDate && (
            <Chip
              label={`Início: ${formatDate(filters.startDate)}`}
              onDelete={() => {
                clearStartDate();
                restartPage();
                clearFilter("startDate");
              }}
            />
          )}
          {filters.endDate && (
            <Chip
              label={`Fim: ${formatDate(filters.endDate)}`}
              onDelete={() => {
                clearEndDate();
                restartPage();
                clearFilter("endDate");
              }}
            />
          )}
        </Box>
      </Box>

      <FiltersDialog
        open={open}
        onClose={handleClickClose}
        client={client}
        clientsOptions={apiData.clients}
        status={status}
        statusOptions={statuses}
        range={range}
        handleRangeChange={handleRangeChange}
        handleApply={handleApply}
        handleClearFilters={handleClearFilters}
      />
    </React.Fragment>
  );
}
