import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import * as React from "react";
import ClearIcon from "@mui/icons-material/Clear";

export default function SearchInput({ value, onChange, placeholder, onSubmit, onClear }) {
  return (
    <Paper
      component="form"
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      sx={{ p: "2px 4px", height: 42, display: "flex", alignItems: "center", width: 400 }}
    >
      <SearchIcon sx={{ opacity: 0.5 }} />

      <InputBase
        value={value}
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        inputProps={{ "aria-label": `search ${placeholder}` }}
        onChange={onChange}
      />

      {value !== "" && (
        <IconButton
          onClick={() => onClear()}
          type="button"
          sx={{ p: "10px" }}
          color="error"
          aria-label="clear"
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      )}
    </Paper>
  );
}
