import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import themeReducer from "./ducks/themeDuck";
import pageReducer from "./ducks/payment-page-reducer";
import financesPageReducer from "./ducks/finances-page-reducer";
import meetingCarouselReducer from "./ducks/meeting-carousel-reducer";

const rootReducer = combineReducers({
  theme: themeReducer,
  paymentPage: pageReducer,
  financesPage: financesPageReducer,
  meetingCarousel: meetingCarouselReducer,
  // other reducers...
});

let store = configureStore({
  reducer: rootReducer,
});

export { store };
