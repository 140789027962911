import Button from "@mui/material/Button";
import { useTheme } from "@mui/system";

type Props = {
  buttonText: string;
  handleButtonClick: any;
  buttonLoadingText?: string;
  startIcon?: any;
  color?: any;
  hoverColor?: any;
  requestLoading?: boolean;
  sx?: any;
  disabled?: boolean;
  isPrimary?: boolean;
  type?: any;
};

export default function CustomLoadingButton({
  startIcon = null,
  color = null,
  hoverColor = null,
  requestLoading,
  handleButtonClick,
  buttonText,
  buttonLoadingText,
  sx,
  disabled,
  isPrimary = false,
  type = "button",
}: Props) {
  const theme = useTheme();

  let buttonSx;
  let bgColor;
  let bgHoverColor;
  if (isPrimary) {
    bgColor = theme.palette.primary.main;
    bgHoverColor = theme.palette.primary.hover;
  } else {
    bgColor = theme.palette.secondary.main;
    bgHoverColor = theme.palette.secondary.hover;
  }

  buttonSx = {
    ...sx,
    backgroundColor: bgColor,
    "&:hover": {
      backgroundColor: bgHoverColor,
    },
  };

  if (!isPrimary) {
    if (color !== null && hoverColor !== null) {
      buttonSx = {
        ...sx,
        backgroundColor: color,
        "&:hover": {
          backgroundColor: hoverColor,
        },
      };
    }
  }

  return (
    <Button
      type={type}
      startIcon={startIcon}
      variant="contained"
      sx={buttonSx}
      disabled={disabled}
      onClick={handleButtonClick}
    >
      {requestLoading ? buttonLoadingText : buttonText}
    </Button>
  );
}
