import { useEffect, useState } from "react";

const useMultipleAutocomplete = (initial: string, options: any[], mapParam: string = "id") => {
  const [selected, setSelected] = useState<any[]>([]);

  useEffect(() => {
    if (initial) {
      if (initial) {
        const initialIds = initial.split(",").map((id) => id.trim());
        const selectedItems = initialIds
          .map((id) => {
            return options.find((obj) => String(obj[mapParam]) === id) || null;
          })
          .filter(Boolean);
        setSelected(selectedItems);
      }
    }
  }, [initial]);

  const handleSelect = (event, newValue) => {
    setSelected(event.target.value);
  };

  const isChecked = (verify) => {
    let isEqual = false;

    selected.forEach((e) => {
      if (e[mapParam] === verify[mapParam]) {
        isEqual = true;
      }
    });
    return isEqual;
  };

  return {
    isChecked,
    selected,
    setSelected,
    handleSelect,
  };
};

export default useMultipleAutocomplete;
