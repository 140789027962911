import { createSlice } from "@reduxjs/toolkit";

export const financePageSlice = createSlice({
  name: "finances-page",
  initialState: {
    value: 0,
  },
  reducers: {
    changeFinancePage: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeFinancePage } = financePageSlice.actions;

export default financePageSlice.reducer;
