import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import AppBarMenu from "../components/AppBarMenu";
import CustomTabPanel from "../components/CustomTabPanel";
import Charges from "../components/finances-management/Charges";
import Finances from "../components/finances-management/Finances";

export default function FinancesManagementPage() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <AppBarMenu>
      <ToastContainer />

      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Cobranças" />
            <Tab label="Finanças" />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Charges />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Finances />
        </CustomTabPanel>
      </Container>
    </AppBarMenu>
  );
}