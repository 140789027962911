import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, IconButton, Slide } from "@mui/material";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import ProjectMeeting from "../../components/meeting/ProjectMeeting";
import { useLoadingToast } from "../../hooks/toast/toasts";
import { useMeetingController } from "./meeting.controller";

export default function MeetingView() {
  const controller = useMeetingController();
  const [direction, setDirection] = useState("left");
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  const handleTransition = (direction) => {
    setDirection(direction);
    setIsTransitioning(true);
    setTimeout(() => {
      setIsTransitioning(false);
    }, 200);
  };

  const iconStyle = { fontSize: 40 };
  const loading = controller.isMeetingListLoading || controller.isMeetingProjectLoading;

  const handleNext = () => {
    handleTransition("left");
    controller.handleNext();
  };
  const handlePrevious = () => {
    handleTransition("right");
    controller.handlePrevious();
  };

  return (
    <div style={{ display: "flex", width: "100%", justifyContent: "center", overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          height: "auto",
        }}
      >
        <ToastContainer />

        <IconButton
          disabled={controller.currentIndex === 0 || loading}
          onClick={() => handlePrevious()}
        >
          <ChevronLeftIcon sx={iconStyle} />
        </IconButton>

        <Slide direction={direction} in={!isTransitioning}>
          <div style={{ width: "100%", height: 490 }}>
            {controller.meetingProject && !isTransitioning ? (
              <ProjectMeeting
                project={controller.meetingProject}
                initializeLoadingToast={initializeLoadingToast}
                updateLoadingToast={updateLoadingToast}
                handleNextProject={handleNext}
              />
            ) : null}
          </div>
        </Slide>

        <IconButton disabled={loading} onClick={() => handleNext()}>
          <ChevronRightIcon sx={iconStyle} />
        </IconButton>
      </Box>
    </div>
  );
}
