import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { usePayments } from "../../../hooks/api/chargesHooks";
import { formatDate } from "../../../services/date";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useQueryClient } from "react-query";
import { useLoadingToast } from "../../../hooks/toast/toasts";
import { useDispatch, useSelector } from "react-redux";
import { changePage } from "../../../store/ducks/payment-page-reducer";
import { markAsPaid } from "../../../services/api/api";
import { FormatMoneyTypography } from "components/format/Formats";

const paymentKinds = {
  1: "Entrada",
  2: "Vistoria",
  3: "Integral",
};

const paymentStatus = {
  PAID: "Recebido",
  MARKED_AS_PAID: "Marcado c/ recebido",
  WAITING: "A receber",
  OVERDUE: "Atrasado",
  EXPIRED: "Expirado",
  CANCELLED: "Cancelado",
};

const paymentColors = {
  PAID: "#00a04e",
  MARKED_AS_PAID: "#71a846",
  WAITING: "#0094dd",
  OVERDUE: "#ffbc31",
  EXPIRED: "#abacb0",
  CANCELLED: "#ff474c",
};

export default function Payments({ filters }) {
  const page = useSelector((state) => state.paymentPage.value);
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const paymentsQuery = usePayments(filters, { page, size: rowsPerPage });
  // const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const queryClient = useQueryClient();
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  const headCells = [
    { id: "projectNumber", label: "Projeto" },
    { id: "name", label: "Nome" },
    { id: "paymentKindId", label: "Cobrança" },
    { id: "clientName", label: "Integrador" },
    { id: "chargeDate", label: "Emissão" },
    { id: "chargeValue", label: "Valor" },
    { id: "status", label: "Status" },
    { id: "actions", label: "Ações" },
  ];

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMarkAsPaid = async () => {
    let paidToastId;

    try {
      paidToastId = initializeLoadingToast({
        text: `Atualizando pagamento...`,
      });
      const { status } = await markAsPaid(selectedRow);

      if (status === 200) {
        queryClient.invalidateQueries({ queryKey: ["payments"] });
        queryClient.invalidateQueries({ queryKey: ["summary"] });

        updateLoadingToast({
          id: paidToastId,
          text: `Pagamento Atualizado com sucesso`,
          type: "success",
        });
        handleClose();
      }
    } catch (error) {
      updateLoadingToast({
        id: paidToastId,
        text:
          error.response.data.message ??
          `Erro ao atualizar pagamento, 
        tente novamente`,
        type: "error",
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    dispatch(changePage(newPage));
  };

  if (paymentsQuery.isLoading) {
    return <CircularProgress />;
  }

  if (paymentsQuery.isError) {
    return <div>Error loading payments...</div>;
  }

  const payments = paymentsQuery.data?.items || [];
  const totalItems = paymentsQuery.data?.pagination.totalItems;

  return (
    <Paper sx={{ mt: 5 }}>
      <TableContainer>
        <Table>
          {/* <CustomTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          /> */}
          <TableHead>
            <TableRow>
              {headCells.map((headCell, i) => (
                <TableCell key={i}>{headCell.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>{item.projects.projectNumber}</TableCell>
                <TableCell>{item.projects.name}</TableCell>
                <TableCell>{paymentKinds[item.paymentKindId]}</TableCell>
                <TableCell>{item.clients.name}</TableCell>
                <TableCell>{item.chargeDate ? formatDate(item.chargeDate) : ""}</TableCell>
                <TableCell>
                  <FormatMoneyTypography value={item.chargeValue ?? 0} />
                </TableCell>

                <TableCell sx={{ maxWidth: 100, textAlign: "center" }}>
                  <Box
                    sx={{
                      backgroundColor: paymentColors[item.status],
                      p: 0.4,
                      borderRadius: 1,
                      textAlign: "center",
                    }}
                  >
                    {paymentStatus[item.status]}
                  </Box>
                  {(item.status === "PAID" || item.status === "MARKED_AS_PAID") && (
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      {item.paymentDate ? formatDate(item.paymentDate) : ""}
                    </Typography>
                  )}
                  {item.status === "EXPIRE" && (
                    <Typography variant="caption">{formatDate(item.expireDate)}</Typography>
                  )}
                </TableCell>
                <TableCell>
                  {item.status !== "PAID" && item.status !== "MARKED_AS_PAID" && (
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, item.id)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  )}

                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleMarkAsPaid}>Marcar como pago</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        labelRowsPerPage="Registros por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        count={totalItems}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        onRowsPerPageChange={() => {}}
      />
    </Paper>
  );
}
