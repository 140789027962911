import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function CustomAutoComplete({
  params,
  options,
  disabled,
  label,
  width,
  margin = 2,
  pt = 1,
}) {
  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      options={options}
      value={params.value}
      onChange={params.onChange}
      isOptionEqualToValue={(option, value) => {
        if (value.name === "") {
          return true;
        }
        return option.name === value.name;
      }}
      getOptionLabel={(option) => {
        if (option === "") {
          return "";
        }
        return option.name;
      }}
      sx={{ width, pt, mb: margin }} // Apply bottom margin
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
