import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { createTheme, Divider, ListItemIcon, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import React, { useContext, useState } from "react";
import { ToastContainer } from "react-toastify";
import AppBarMenu from "../components/AppBarMenu";
import CustomAutoComplete from "../components/autoCompletes/CustomAutoComplete";
import { ApiDataContext } from "../contexts/ApiDataContext";
import useDialog from "../hooks/dialog/useDialog";
import { useManagementTextField } from "../hooks/forms/ManagementHooks";
import { useLoadingToast } from "../hooks/toast/toasts";
import { getClientStages, sendClientsMessage, sendClientStagesReport } from "../services/api/api";

const theme = createTheme({
  palette: {
    primary: {
      main: "#128C7E",
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function StagesPage() {
  const { apiData } = useContext(ApiDataContext);
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  const [clientValue, setClientValue] = useState("");

  const [clientStagesData, setClientStagesData] = useState();
  const [clientStagesDataLoading, setClientStagesDataLoading] = useState(false);

  const handleClientChange = async (event, newValue) => {
    try {
      setClientValue(newValue);
      setClientStagesDataLoading(true);

      const { data } = await getClientStages(newValue.id);
      setClientStagesData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setClientStagesDataLoading(false);
    }
  };

  const client = {
    value: clientValue,
    onChange: handleClientChange,
  };

  //START -> Report Dialog items
  const {
    open: reportDialogOpen,
    handleClickOpen: openReportDialog,
    handleClickClose: closeReportDialog,
  } = useDialog();

  const [reportRequestLoading, setReportRequestLoading] = useState(false);

  const sendReport = async () => {
    let signUpToastId;
    try {
      setReportRequestLoading(true);
      signUpToastId = initializeLoadingToast({
        text: `Enviando Relatório...`,
      });

      const { status } = await sendClientStagesReport(selectedReportClients);

      if (status === 200) {
        updateLoadingToast({
          id: signUpToastId,
          text: `Relatório envviado com sucesso`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: signUpToastId,
        text: error.response.data.message ?? `Erro ao Enviar Relatório, tente novamente`,
        type: "error",
      });
    } finally {
      setReportRequestLoading(false);
      closeReportDialog();
    }
  };

  const [selectedReportClients, setSelectedReportClients] = useState([]);

  const selectReportClients = (event) => {
    setSelectedReportClients(event.target.value);
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      if (selectedReportClients.length === apiData.clients.length) {
        setSelectedReportClients([]);
      } else {
        setSelectedReportClients(apiData.clients);
      }
      return;
    }
    setSelectedReportClients(value);
  };

  const isReportClientChecked = (selected) => {
    let isEqual = false;

    selectedReportClients.forEach((e) => {
      if (e.id === selected.id) {
        isEqual = true;
      }
    });
    return isEqual;
  };

  const areAllReportSelected =
    apiData.clients.length > 0 && selectedReportClients.length === apiData.clients.length;
  //END -> Report Dialog items
  //START -> Message Dialog items
  const {
    open: messageDialogOpen,
    handleClickOpen: openMessageDialog,
    handleClickClose: closeMessageDialog,
  } = useDialog();

  const [messageRequestLoading, setMessageRequestLoading] = useState(false);

  const sendMessage = async () => {
    let messageToastId;
    try {
      setMessageRequestLoading(true);
      messageToastId = initializeLoadingToast({
        text: `Enviando Mensagem a Integradores...`,
      });

      const { status } = await sendClientsMessage(selectedMessageClients, message.value);

      if (status === 200) {
        updateLoadingToast({
          id: messageToastId,
          text: `Mensagem enviada com sucesso`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: messageToastId,
        text: error.response.data.message ?? `Erro ao Enviar Mensagem, tente novamente`,
        type: "error",
      });
    } finally {
      setMessageRequestLoading(false);
      closeMessageDialog();
    }
  };

  const [selectedMessageClients, setSelectedMessageClients] = useState([]);

  const selectMessageClients = (event) => {
    setSelectedMessageClients(event.target.value);
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      if (selectedMessageClients.length === apiData.clients.length) {
        setSelectedMessageClients([]);
      } else {
        setSelectedMessageClients(apiData.clients);
      }
      return;
    }
    setSelectedMessageClients(value);
  };

  const isMessageClientChecked = (selected) => {
    let isEqual = false;

    selectedMessageClients.forEach((e) => {
      if (e.id === selected.id) {
        isEqual = true;
      }
    });
    return isEqual;
  };

  const areAllMessageSelected =
    apiData.clients.length > 0 && selectedMessageClients.length === apiData.clients.length;

  const { reset: resetMessage, ...message } = useManagementTextField("");
  //END -> Message Dialog items

  const clientsSelector = ({ value, handleChange, handleCheck, areAllSelected }) => {
    return (
      <FormControl sx={{ width: "100%", mt: 0.5 }}>
        <InputLabel>Integradores</InputLabel>
        <Select
          multiple
          value={value}
          onChange={handleChange}
          input={<OutlinedInput label="Integradores" />}
          renderValue={(client) => {
            const initialValue = "";
            const str = client.reduce(
              (accumulator, currentValue) => accumulator + currentValue.name + ", ",
              initialValue
            );
            return str;
          }}
          MenuProps={MenuProps}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={areAllSelected}
                indeterminate={value.length > 0 && value.length < apiData.clients.length}
              />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: "bold" }}>Selecione todos</Typography>
            </ListItemText>
          </MenuItem>

          {apiData.clients.map((value) => (
            <MenuItem key={value.id} value={value}>
              <Checkbox checked={handleCheck(value)} />
              <ListItemText primary={value.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const createDialog = ({
    title,
    content,
    isOpen,
    isLoading,
    handleClose,
    handleSend,
    height = 150,
  }) => {
    return (
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ width: 600, height }}>{content}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading} variant="outlined">
            Cancelar
          </Button>

          <Button
            onClick={handleSend}
            disabled={isLoading}
            variant="contained"
            theme={theme}
            autoFocus
          >
            {isLoading ? "Enviando..." : "Enviar"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const barButtons = (
    <Box
      sx={{
        marginLeft: "auto",
        marginRight: "50px",
        display: "flex",
        justifyContent: "space-around",
        width: "350px",
      }}
    >
      <Button
        variant="contained"
        theme={theme}
        startIcon={<WhatsAppIcon />}
        onClick={openReportDialog}
      >
        Relatório
      </Button>

      <Button
        variant="contained"
        theme={theme}
        startIcon={<ElectricBoltIcon />}
        onClick={openMessageDialog}
      >
        Mensagem Rápida
      </Button>
    </Box>
  );

  return (
    <AppBarMenu icons={barButtons}>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {createDialog({
          title: "Escolha os integradores a receber o relatório",
          content: clientsSelector({
            value: selectedReportClients,
            handleChange: selectReportClients,
            handleCheck: isReportClientChecked,
            areAllSelected: areAllReportSelected,
          }),
          isOpen: reportDialogOpen,
          isLoading: reportRequestLoading,
          handleClose: closeReportDialog,
          handleSend: sendReport,
        })}

        {createDialog({
          title: "Mensagem rápida para os integradores",
          height: 250,
          content: (
            <Box>
              {clientsSelector({
                value: selectedMessageClients,
                handleChange: selectMessageClients,
                handleCheck: isMessageClientChecked,
                areAllSelected: areAllMessageSelected,
              })}
              <TextField
                sx={{ mt: 2 }}
                label="Mensagem"
                multiline
                fullWidth
                rows={6}
                disabled={reportRequestLoading}
                value={message.value}
                onChange={message.onChange}
              />
            </Box>
          ),

          isOpen: messageDialogOpen,
          isLoading: messageRequestLoading,
          handleClose: closeMessageDialog,
          handleSend: sendMessage,
        })}

        <ToastContainer />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
                minHeight: 600,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  mb: 2,
                  alignSelf: "center",
                }}
              >
                Resumo
              </Typography>
              <Box>
                <CustomAutoComplete
                  params={client}
                  options={apiData.clients}
                  disabled={false}
                  label="Selecione o Integrador"
                  width="40%"
                />

                {clientStagesDataLoading === true ? (
                  <Box display="flex" width="100%" gap={2} sx={{ mt: 2 }}>
                    <Skeleton variant="rounded" width="45%" height={100} sx={{ mt: 2 }} />
                    <Skeleton variant="rounded" width="45%" height={100} sx={{ mt: 2 }} />
                  </Box>
                ) : !clientStagesData ? null : (
                  <Box display="flex" flexWrap="wrap" width="100%" gap={2} sx={{ mt: 2 }}>
                    {Object.entries(clientStagesData).map((entry) => (
                      <Box
                        key={entry[0]}
                        width="45%"
                        display="flex"
                        flexDirection="column"
                        sx={{
                          justifyContent: "flex-start",
                          alignItems: "center",
                          alignContent: "flex-start",
                          flexGrow: 0,
                        }}
                      >
                        <Box
                          width="100%"
                          display="flex"
                          flexDirection="column"
                          sx={{
                            backgroundColor: "#CDCDCD",
                            borderRadius: "15px 15px 0 0",
                            justifyContent: "center",
                            alignItems: "center",
                            p: 2,
                          }}
                        >
                          <Typography variant="title">{entry[0]}</Typography>
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="column"
                          alignSelf="flex-start"
                          width="100%"
                          sx={{
                            backgroundColor: "#D9D9D9",
                            borderRadius: "0 0 15px 15px",
                            p: 2,
                          }}
                        >
                          {entry[1]?.map((clientInfo, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Typography>
                                  {clientInfo.projectNumber} - {clientInfo.projectName}
                                </Typography>
                                <Divider />
                              </React.Fragment>
                            );
                          })}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
                <Box></Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </AppBarMenu>
  );
}
