import { Container } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBarMenu from "../../components/AppBarMenu";
import CustomButton from "../../components/button/CustomButton";
import MeetingView from "./meeting.view";

export default function NewMeetingPage() {
  const dispatch = useDispatch();
  const currentIndex = useSelector((state) => state.meetingCarousel.currentIndex);
  const lastSeenProject = JSON.parse(sessionStorage.getItem("lastSeenProject"));

  const appBarItems =
    lastSeenProject && lastSeenProject.index !== currentIndex ? (
      <CustomButton
        buttonText={`Último projeto Visto - ${lastSeenProject.projectNumber}`}
        sx={{
          marginLeft: "auto",
          marginRight: "50px",
        }}
        disabled={false}
        handleButtonClick={() => {
          dispatch({ type: "SET_PROJECT", payload: lastSeenProject.index });
        }}
      />
    ) : null;

  return (
    <AppBarMenu icons={appBarItems}>
      <Container maxWidth={false} sx={{ maxWidth: "98%", p: 4 }}>
        <MeetingView />
      </Container>
    </AppBarMenu>
  );
}
