import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

type Props = {
  params: any;
  disabled?: boolean;
  label?: string;
  maxLength?: number;
  width?: string | number;
  required?: boolean;
  multiline?: boolean;
  maxRows?: number;
  margin?: number;
  sx?: Object;
};

export default function CustomTextField({
  params,
  disabled,
  label,
  maxLength,
  width,
  required,
  multiline,
  maxRows,
  margin = 2, // Default margin value
  sx,
}: Props) {
  const { value, onChange, error, errorLabel } = params;

  return (
    <Box width={width} sx={{ ...sx, mb: margin }}>
      <TextField
        multiline={multiline}
        maxRows={maxRows}
        value={value}
        onChange={onChange}
        fullWidth
        required={required}
        disabled={disabled}
        label={label}
        inputProps={{ maxLength: maxLength }}
      />
      <Typography color="error">{error ? errorLabel : ""}</Typography>
    </Box>
  );
}
