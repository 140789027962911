import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

export default function CustomContentDialog({
  open,
  handleClickClose,
  title,
  content,
  requestLoading,
  handleConfirmationClick,
  confirmationClickText,
  requestLoadingText,
  width = 600,
  height = 150,
  firstButtonColor = "primary",
  secondButtonColor = "error",
}) {
  return (
    <Dialog open={open} onClose={handleClickClose} maxWidth={width}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{width, height}} >{content}</DialogContent>
      <DialogActions>
        <Button
          onClick={handleClickClose}
          variant="outlined"
          disabled={requestLoading}
          color={secondButtonColor}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleConfirmationClick}
          disabled={requestLoading}
          variant="contained"
          color={firstButtonColor}
          autoFocus
        >
          {requestLoading ? requestLoadingText : confirmationClickText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
