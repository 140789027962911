import { api } from "../api";

export const getPricesTables = async () => {
  let url = `/admin/pricesTable`;
  return await api.get(url);
};

export const createPricesTable = async (data: any) => {
  let url = `/admin/pricesTable`;
  return await api.post(url, data);
};

export const updatePricesTable = async (tableId: number, data: any) => {
  let url = `/admin/pricesTable/${tableId}`;
  return await api.put(url, data);
};

export const deletePricesTable = async (tableId: number) => {
  let url = `/admin/pricesTable/${tableId}`;
  return await api.delete(url);
};
