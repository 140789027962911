import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function StagesAutoComplete({ params, disabled, options, label, width, sx }) {
  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      options={options}
      {...params}
      isOptionEqualToValue={(option, value) => {
        if (value.stageName === "") {
          return true;
        }
        return option.stageName === value.stageName;
      }}
      getOptionLabel={(option) => {
        if (option === "") {
          return "";
        }
        return option.stageName;
      }}
      sx={{ ...sx, width: width }}
      renderInput={(params) => <TextField {...params} label={label} required />}
    />
  );
}
