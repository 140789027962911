import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type Props = {
  open: boolean;
  handleClickClose: any;
  title: any;
  contentText: string;
  handleConfirmationClick: any;
  confirmationClickText: string;
  requestLoading?: boolean;
  requestLoadingText?: string;
  width: string | number;
  height: string | number;
};

export default function CustomDialog({
  open,
  handleClickClose,
  title,
  contentText,
  requestLoading,
  handleConfirmationClick,
  confirmationClickText,
  requestLoadingText,
  width = "auto",
  height = "auto",
}: Props) {
  return (
    <Dialog open={open} onClose={handleClickClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ width, height }}>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickClose} variant="outlined" disabled={requestLoading}>
          Cancelar
        </Button>
        <Button
          onClick={handleConfirmationClick}
          disabled={requestLoading}
          variant="contained"
          color="error"
          autoFocus
        >
          {requestLoading ? requestLoadingText : confirmationClickText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
