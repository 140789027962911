import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { subDays } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Filters from "./finances/F-Filters";
import FinancesProjects from "./finances/F-Projects";
import Summary from "./shared/Summary";
import { useFinancesSummary } from "../../hooks/api/financesHooks";
import GridOnIcon from "@mui/icons-material/GridOn";
import { getFinancesReport } from "services/api/api";
import CustomLoadingButton from "components/button/CustomLoadingButton";

const validateArrayOrNumber = (param) => {
  if (!param) return "";

  if (typeof param === "string" && param.includes(",")) {
    const parsedArray = param
      .split(",")
      .map((num) => parseInt(num.trim(), 10))
      .filter(Number.isInteger);
    return parsedArray.length ? parsedArray : "";
  }

  const parsedNumber = parseInt(param, 10);
  if (Number.isInteger(parsedNumber) && parsedNumber > 0) {
    return [parsedNumber];
  }

  return "";
};

const validateNumber = (clientId) => {
  if (!clientId) return "";
  const id = parseInt(clientId, 10);
  if (Number.isInteger(id) && id > 0) {
    return id;
  }
  return "";
};

const validateSearchParams = (searchParams) => {
  const stage = validateArrayOrNumber(searchParams.get("stage"));
  const clientId = validateNumber(searchParams.get("clientId"));
  // const startDate = searchParams.get("startDate") || subDays(new Date(), 30).toISOString();
  const startDate = searchParams.get("startDate") || "";
  // const endDate = searchParams.get("endDate") || new Date().toISOString().split("T")[0];
  const endDate = searchParams.get("endDate") || "";
  const search = searchParams.get("search") || "";
  return {
    stage,
    clientId,
    startDate,
    endDate,
    search,
  };
};

export default function Finances() {
  const [searchParams, setSearchParams] = useSearchParams();
  const validSearchParams = validateSearchParams(searchParams);
  const [filters, setFilters] = useState(validSearchParams);
  const [isXlsxloading, setXlsxLoading] = useState(false);

  useEffect(() => {
    setSearchParams(filters);
  }, [filters, setSearchParams]);

  const applyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const clearFilter = (filterKey) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: "",
    }));
  };

  const memoizedFilters = useMemo(() => filters, [filters]);
  const { data: summaryData, isLoading: summaryDataLoading } = useFinancesSummary(memoizedFilters);

  const handleXlsxClick = async () => {
    try {
      setXlsxLoading(true);

      const { status, data } = await getFinancesReport(memoizedFilters);

      if (status === 200) {
        const blobUrl = URL.createObjectURL(data);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = "relatorio_financas.xlsx";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setXlsxLoading(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            minHeight: 600,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mb: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              Gestão de Finanças
            </Typography>

            <CustomLoadingButton
              startIcon={<GridOnIcon />}
              buttonText="Exportar Planilha"
              buttonLoadingText="Exportando..."
              sx={{ width: "fit-content", height: "70%" }}
              disabled={isXlsxloading}
              handleButtonClick={handleXlsxClick}
              requestLoading={isXlsxloading}
            />
          </Box>
          <Divider sx={{ mb: 2 }} />

          <Filters filters={filters} applyFilters={applyFilters} clearFilter={clearFilter} />
          <Summary isLoading={summaryDataLoading} data={summaryData} width="100%" compWidth="22%" />
          <FinancesProjects filters={filters} />
        </Paper>
      </Grid>
    </Grid>
  );
}
