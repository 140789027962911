import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import MultipleAutocomplete from "components/autoCompletes/MultipleAutocomplete";

export default function FiltersDialog({
  open,
  onClose,
  handleApply,
  handleClearFilters,
  providence,
  providencesOptions,
  isProvidenceChecked,
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={handleClearFilters}>
            <FilterAltOffIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <MultipleAutocomplete
          sx={{ mt: 2 }}
          width={"100%"}
          required={false}
          disabled={false}
          valueKey="providenceName"
          label="Selecione as Providências"
          params={providence}
          values={providencesOptions}
          isChecked={isProvidenceChecked}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleApply} variant="contained" color="primary">
          Aplicar Filtros
        </Button>
      </DialogActions>
    </Dialog>
  );
}
