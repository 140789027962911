import { api } from "../api";

export const createProject = async (
  data
  //  {
  //   clientId,
  //   internalClient,
  //   projectNumber,
  //   projectName,
  //   stageId,
  //   providenceId,
  //   deadline,
  //   powerDistributionCompanyId,
  //   engineerId,
  //   power,
  //   price,
  //   note,
  //   clientType,
  //   npParams,
  //   leParams,
  //   street,
  //   number,
  //   district,
  //   addressDetails,
  //   city,
  //   state,
  //   zipCode
  // }
) => {
  let url = `/projects`;

  return await api.post(url, data);
};

export const deleteProject = async (projectId) => {
  let url = `/projects/${projectId}`;

  return await api.delete(url);
};

export const generateAttorneyPdf = async (projectId) => {
  let url = `/projects/${projectId}/attorney`;
  return await api
    .post(
      url,
      {},
      {
        responseType: "blob",
      }
    )
    .catch((error) => {
      const contentType = error.response.headers["content-type"];

      if (contentType.includes("application/json")) {
        // If it's JSON, we assume it's an error or non-blob response
        return error.response.data.text().then((text) => {
          const jsonData = JSON.parse(text);
          throw new Error(jsonData.message || "Erro ao gerar procuração, tente novamente");
        });
      }

      throw error;
    });
};
