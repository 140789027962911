import axios from "axios";
const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : process.env.REACT_APP_BASE_URL;

export const api = axios.create({
  baseURL: baseURL,
});

export * from "./accounts/accountsApi";
export * from "./admin/adminEmailsApi";
export * from "./admin/adminUsersApi";
export * from "./baseData/baseDataApi";
export * from "./clients/clientsApi";
export * from "./comissions/comissionRulesApi";
export * from "./comissions/comissionsApi";
export * from "./comissions/comissionVariablesApi";
export * from "./home/homeApi";
export * from "./manage/manageApi";
export * from "./meeting/meetingApi";
export * from "./powerDistributionCompany/pdcApi";
export * from "./projects/projectsApi";
export * from "./stages/stagesApi";
export * from "./userProvidences/userProvidencesApi";
export * from "./users/usersApi";
export * from "./billing/billingApi";
export * from "./engineers/engineersApi";
export * from "./files/oneDrive";
export * from "./charges/chargesApi";
export * from "./finances/financesApi";
