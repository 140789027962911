import { api } from "../api";

export const getNextInvoiceId = async () => {
  let url = "/clients/nextInvoiceId";
  return await api.get(url);
};

export const getClients = async () => {
  let url = "/clients";
  return await api.get(url);
};

export const deleteClient = async (clientId, engineerId) => {
  let url = `/clients/${clientId}`;
  return await api.delete(url, {
    engineerId: engineerId,
  });
};

export const updateClient = async (
  // clientId,
  // clientName,
  // cellPhone,
  // email,
  // powerDistributionCompanies,
  // isEngineer,
  // willReceiveAutomaticCharges,
  // communicationChannel,
  // invoiceId,
  // isActive,
  // cpf,
  // cnpj,
  // street,
  // number,
  // district,
  // addressDetails,
  // city,
  // state,
  // zipCode
  clientId,
  data
) => {
  let url = `/clients/${clientId}`;

  return await api.put(url, data);
};

export const createClient = async (
  // clientName,
  // cellPhone,
  // email,
  // powerDistributionCompanies,
  // isEngineer,
  // willReceiveAutomaticCharges,
  // communicationChannel,
  // invoiceId,
  // cpf,
  // cnpj,
  // priceTable,
  // street,
  // number,
  // district,
  // addressDetails,
  // city,
  // state,
  // zipCode
  data
) => {
  let url = `/clients`;

  return await api.post(url, data);
};

export const getServiceProvisionContract = async (
  clientName,
  clientType,
  clientDoc,
  street,
  number,
  district,
  city,
  state,
  zipCode,
  email
) => {
  let url = `/clients/service-contract`;

  return await api.post(
    url,
    {
      clientName,
      clientType,
      clientDoc,
      street,
      number,
      district,
      city,
      state,
      zipCode,
      email,
    },
    {
      responseType: "blob",
    }
  );
};
