import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  IconButton,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useFetchPrices } from "hooks/api/adminApiHooks";
import useDialog from "hooks/dialog/useDialog";
import { useErrorToast, useLoadingToast } from "hooks/toast/toasts";
import { useState } from "react";
import { updatePriceById } from "services/api/admin/adminPricesApi";
import PricesRow from "../prices-row";

import CustomDialog from "components/dialog/CustomDialog";
import { useQueryClient } from "react-query";
import { usePricesTable } from "./usePricesTable";

const TitleCell = styled(TableCell)({
  fontSize: "1rem",
  fontWeight: "semi-bold",
  fontStyle: "italic",
});

const DEFAULT_TABLES = [1, 2, 3];

export default function PricesTable({ width, table, handleDeleteTable }) {
  const {
    pricesLoading,
    pricesData,
    deleteOpen,
    handleClickDeleteClose,
    initializeErrorToast,
    handleClickDeleteOpen,
    editingId,
    isEditing,
    handleEdit,
    handleCancel,
    handleSave,
  } = usePricesTable(table);

  return (
    <>
      {pricesLoading ? (
        <Skeleton variant="rounded" width="100%" height={130} />
      ) : pricesData ? (
        <Box width={width} sx={{ boxShadow: 3 }}>
          <CustomDialog
            open={deleteOpen}
            handleClickClose={handleClickDeleteClose}
            handleConfirmationClick={() => {
              handleDeleteTable();
              handleClickDeleteClose();
            }}
            title="Excluir tabela?"
            contentText="A tabela não poderá ser recuperada"
            confirmationClickText="Excluir"
            width={350}
            height={60}
          />

          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
              backgroundColor: "#EEEEEE",
            }}
          >
            <Typography sx={{ fontSize: "1.2rem", fontWeight: 600, textAlign: "center" }}>
              {table.name || "Tabela sem nome"}
            </Typography>

            {DEFAULT_TABLES.includes(table.id) ? null : (
              <IconButton
                sx={{
                  ml: "1rem",
                }}
                onClick={() => {
                  if (DEFAULT_TABLES.includes(table.id)) {
                    initializeErrorToast({
                      text: `Não é possível excluir tabelas padrão`,
                      options: {},
                    });
                    return;
                  }

                  handleClickDeleteOpen();
                }}
              >
                <DeleteOutlineIcon color="error" />
              </IconButton>
            )}
          </Box>
          <Table sx={{ p: 1 }} size="small">
            <TableHead>
              <TableRow>
                <TitleCell sx={{ p: 2 }}>Inicial (kWh)</TitleCell>
                <TitleCell>Final (kWh)</TitleCell>
                <TitleCell>Preço </TitleCell>
                <TitleCell></TitleCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pricesData.map((price) => (
                <PricesRow
                  key={price.id}
                  row={price}
                  editingId={editingId}
                  isEditing={isEditing}
                  handleEdit={handleEdit}
                  handleCancel={handleCancel}
                  handleSave={handleSave}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      ) : null}
    </>
  );
}
