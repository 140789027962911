import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  ".MuiAccordionSummary-root": {
    backgroundColor: "#EEEEEE",
    borderRadius: "0.625rem 0.625rem 0 0",
    height: "60px",
  },
  "&.MuiAccordion-rounded": {
    borderRadius: "0.625rem 0.625rem 0 0",
    // boxShadow: 3
  },
  "&.Mui-expanded": {
    "&.MuiAccordion-rounded": {
      borderRadius: "0.625rem",
      margin: "0px",
    },
  },
}));

export default function CustomAccordion({
  children,
  title,
  titleActions,
  actions,
  height,
  expanded,
  onChange,
}) {
  return (
    <StyledAccordion
      expanded={expanded ?? undefined}
      onChange={(e, isExpanded) => {
        if (onChange) onChange(e, isExpanded);
      }}
      sx={{ boxShadow: 3, m: 0 }}
      //   slotProps={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "1.1rem", fontWeight: 600 }}>{title}</Typography>
          {titleActions}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{ width: "100%", display: "flex", flexDirection: "column", height: height, p: 2 }}
      >
        {children}
      </AccordionDetails>
      <AccordionActions>{actions}</AccordionActions>
    </StyledAccordion>
  );
}
