import { api } from "../api";

// Filters:
// startDate: Date
// endDate: Date
// clientIds: number[]
export const getFinancesProjects = async (filters) => {
  let url = "/finances";
  return await api.get(url, {
    params: filters,
  });
};

export const getFinancesSummary = async (filters) => {
  let url = "/finances/summary";
  return await api.get(url, {
    params: filters,
  });
};

export const getFinancesReport = async (filters) => {
  let url = `/finances/report`;

  return await api.get(url, {
    params: filters,
    responseType: "blob",
  });
};
