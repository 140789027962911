import { useFetchPricesTables } from "hooks/api/adminApiHooks";
import useDialog from "hooks/dialog/useDialog";
import { useAutocomplete } from "hooks/forms/FormHooks";
import { useManagementTextField } from "hooks/forms/ManagementHooks";
import { useLoadingToast } from "hooks/toast/toasts";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { createPricesTable, deletePricesTable } from "services/api/admin/adminPricesTablesApi";

type Return = {
  open: boolean;
  handleClickClose: () => void;
  handleCreateNewTable: () => Promise<void>;
  isLoading: boolean;
  tableName: any;
  table: any;
  pricesLoading: boolean;
  pricesTableData: any;
  handleClickOpen: () => void;
  handleDeleteTable: () => Promise<void>;
};

export const usePricesPanel = (): Return => {
  const { pricesLoading, pricesTableData } = useFetchPricesTables();
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();
  const { reset: resetTable, ...table } = useAutocomplete();
  const { open, handleClickOpen, handleClickClose } = useDialog();
  const tableName = useManagementTextField("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleCreateNewTable = async () => {
    setLoading(true);
    let createToastId = initializeLoadingToast({
      text: `Criando Tabela...`,
      options: {},
    });

    try {
      await createPricesTable({ name: tableName.value });
      queryClient.invalidateQueries({ queryKey: ["prices_tables"] });
      tableName.onCustomChange("");
      resetTable();

      updateLoadingToast({
        id: createToastId,
        text: `Tabela Criada com sucesso`,
        type: "success",
      });
    } catch (error) {
      const err = error as any;
      updateLoadingToast({
        id: createToastId,
        text: err.response.data.message ?? "Erro ao criar tabela, tente novamente",
        type: "error",
      });
    } finally {
      setLoading(false);
      handleClickClose();
    }
  };

  const handleDeleteTable = async () => {
    setDeleteLoading(true);
    let deleteToastId = initializeLoadingToast({
      text: `Excluindo Tabela...`,
      options: {},
    });

    try {
      await deletePricesTable(table.value.id);
      queryClient.invalidateQueries({ queryKey: ["prices_tables"] });
      resetTable();

      updateLoadingToast({
        id: deleteToastId,
        text: `Tabela Excluída com sucesso`,
        type: "success",
      });
    } catch (error) {
      const err = error as any;
      updateLoadingToast({
        id: deleteToastId,
        text: err.response.data.message ?? "Erro ao excluir tabela, tente novamente",
        type: "error",
      });
    } finally {
      setLoading(false);
      handleClickClose();
    }
  };

  return {
    open,
    handleClickOpen,
    handleClickClose,

    isLoading,
    handleCreateNewTable,
    handleDeleteTable,

    tableName,
    table,
    pricesTableData,
    pricesLoading,
  };
};
