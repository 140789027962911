import Box from "@mui/material/Box";
import React, { useContext, useState } from "react";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Badge, Button } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import useDialog from "hooks/dialog/useDialog";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import FiltersDialog from "./FiltersDialog";
import { ApiDataContext } from "contexts/ApiDataContext";

export default function SearchBar({
  data,
  isMyActivitiesMarked,
  setIsMyActivitiesMarked,
  searchValue,
  setSearchValue,
  setProvidencesFilter,
  providencesFilter,
}) {
  const { apiData } = useContext(ApiDataContext);

  const handleOnSearch = (string, results) => {
    // setSearchValue(results);
  };

  const handleOnHover = (result) => {};

  const handleOnSelect = (item) => {
    // window.location = `/activities?id=${item.id}`;
    setSearchValue(item);
  };

  const handleOnFocus = () => {};

  const handleOnClear = () => {
    setSearchValue([]);
  };

  const handleSwitchClick = () => {
    setIsMyActivitiesMarked(!isMyActivitiesMarked);
  };

  const { handleClickOpen, handleClickClose, open } = useDialog();

  const [selectedProvidences, setSelectedProvidences] = useState([]);

  const handleProvidenceSelect = (event) => {
    setSelectedProvidences(event.target.value);
  };

  const isProvidenceChecked = (selected) => {
    let isEqual = false;

    selectedProvidences.forEach((e) => {
      if (e.id === selected.id) {
        isEqual = true;
      }
    });
    return isEqual;
  };

  const userProvidencesParams = {
    value: selectedProvidences,
    onChange: handleProvidenceSelect,
  };

  const handleClearFilters = () => {
    setSelectedProvidences([]);
    setProvidencesFilter([]);
  };

  const handleApplyFilters = () => {
    console.log(selectedProvidences);
    const provs = selectedProvidences.map((p) => p.id);
    console.log(provs);
    setProvidencesFilter(selectedProvidences.map((p) => p.id));
    handleClickClose();
  };

  const formatResult = (item) => {
    return (
      <div className="result-wrapper">
        <span className="result-span">
          {item.projectNumber} - {item.name}
        </span>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Box display="flex" width="100%">
        <Box width="65%" sx={{ p: 1 }}>
          <ReactSearchAutocomplete
            items={data || []}
            maxResults={15}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            placeholder="Busque por nome ou numero"
            onClear={handleOnClear}
            showNoResultsText="Nenhum resultado"
            fuseOptions={{ keys: ["projectNumber", "name"] }}
            resultStringKeyName="name"
            formatResult={formatResult}
            styling={{
              zIndex: 4,
            }}
            value={searchValue}
            className="search" // To display it on top of the search box below
          />
        </Box>

        <Box width="20%" sx={{ p: 1 }}>
          <FormControlLabel
            control={<Switch onClick={handleSwitchClick} checked={isMyActivitiesMarked} />}
            label="Minhas Atividades"
          />
        </Box>
        <Box width="15%" height="45%" sx={{ p: 1 }}>
          <Badge
            invisible={!providencesFilter || providencesFilter.length === 0}
            badgeContent={providencesFilter.length}
            color="secondary"
          >
            <Button
              sx={{ width: "100%", borderRadius: 2, alignSelf: "center" }}
              variant="contained"
              endIcon={<FilterAltIcon />}
              onClick={handleClickOpen}
            >
              Filtros
            </Button>
          </Badge>
        </Box>
      </Box>

      <FiltersDialog
        open={open}
        onClose={handleClickClose}
        providencesOptions={apiData.providences}
        providence={userProvidencesParams}
        isProvidenceChecked={isProvidenceChecked}
        handleApply={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </React.Fragment>
  );
}
