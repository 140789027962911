import { useQuery } from "react-query";
import { getAllowedEmails, getUsers } from "../../services/api/api";
import { getPricesTables } from "services/api/admin/adminPricesTablesApi";
import { getPricesByTableId } from "services/api/admin/adminPricesApi";

const adminApi = {
  fetchAllowedEmails: async () => {
    const { data } = await getAllowedEmails();
    return data;
  },
  fetchUsers: async () => {
    const { data } = await getUsers();
    return data;
  },
  fetchPricesTables: async () => {
    const { data } = await getPricesTables();
    return data;
  },
  fetchPrices: async (tableId: number) => {
    const { data } = await getPricesByTableId(tableId);
    return data;
  },
};

export const useFetchAllowedEmails = () => {
  const { isLoading: allowedEmailsLoading, data: allowedEmailsData } = useQuery(
    "allowedEmails",
    adminApi.fetchAllowedEmails,
    {
      staleTime: Infinity,
      retry: 5,
    }
  );

  return {
    allowedEmailsLoading,
    allowedEmailsData,
  };
};

export const useFetchUsers = () => {
  const { isLoading: usersLoading, data: usersData } = useQuery("users", adminApi.fetchUsers, {
    staleTime: Infinity,
    retry: 5,
  });

  return {
    usersLoading,
    usersData,
  };
};

export const useFetchPricesTables = () => {
  const { isLoading: pricesLoading, data: pricesTableData } = useQuery(
    "prices_tables",
    adminApi.fetchPricesTables,
    {
      staleTime: Infinity,
      retry: 5,
    }
  );

  return {
    pricesLoading,
    pricesTableData,
  };
};

export const  useFetchPrices = (tableId: number) => {
  const { isLoading: pricesLoading, data: pricesData } = useQuery(
    ["prices", tableId],
    () => adminApi.fetchPrices(tableId),
    {
      staleTime: Infinity,
      retry: 5,
    }
  );

  return {
    pricesLoading,
    pricesData,
  };
};
