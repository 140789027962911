import { useFetchPrices } from "hooks/api/adminApiHooks";
import useDialog from "hooks/dialog/useDialog";
import { useErrorToast, useLoadingToast } from "hooks/toast/toasts";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { updatePriceById } from "services/api/admin/adminPricesApi";

type Return = {
  handleSave: (id: number, price: number) => Promise<void>;
  handleCancel: () => void;
  handleEdit: (id: number) => void;
  handleClickDeleteOpen: () => void;
  handleClickDeleteClose: () => void;
  isEditing: boolean;
  editingId: number | null;
  initializeErrorToast: any;
  deleteOpen: boolean;
  pricesData: any;
  pricesLoading: boolean;
};

export const usePricesTable = (table: any): Return => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingId, setEditingId] = useState<number | null>(null);
  const {
    open: deleteOpen,
    handleClickOpen: handleClickDeleteOpen,
    handleClickClose: handleClickDeleteClose,
  } = useDialog();
  const { initializeErrorToast } = useErrorToast();
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();
  const queryClient = useQueryClient();

  const handleEdit = (id: number) => {
    setIsEditing(true);
    setEditingId(id);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditingId(null);
  };

  const handleSave = async (id: number, price: number) => {
    let updateToastId = initializeLoadingToast({
      text: `Atualizando Preço...`,
      options: {},
    });

    try {
      await updatePriceById(id, price);
      queryClient.invalidateQueries({ queryKey: ["prices"] });

      updateLoadingToast({
        id: updateToastId,
        text: `Preço Atualizado com sucesso`,
        type: "success",
      });
    } catch (error) {
      const err = error as any;
      updateLoadingToast({
        id: updateToastId,
        text: err.response.data.message ?? "Erro ao atualizar preço, tente novamente",
        type: "error",
      });
    } finally {
      setIsEditing(false);
      setEditingId(null);
    }
  };

  const { pricesLoading, pricesData } = useFetchPrices(table.id);

  return {
    handleSave,
    handleCancel,
    handleEdit,
    handleClickDeleteOpen,
    isEditing,
    editingId,
    initializeErrorToast,
    handleClickDeleteClose,
    deleteOpen,
    pricesData,
    pricesLoading,
  };
};
