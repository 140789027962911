import { useDispatch, useSelector } from "react-redux";
import { useFetchMeetingList, useFetchMeetingProject } from "./meeting.hooks";

export const useMeetingController = () => {
  const dispatch = useDispatch();
  const currentIndex = useSelector((state) => state.meetingCarousel.currentIndex);
  const { isMeetingListLoading, meetingList } = useFetchMeetingList();

  const isValidIndex = meetingList && currentIndex >= 0 && currentIndex < meetingList.length;

  const { isMeetingProjectLoading, meetingProject } = useFetchMeetingProject(
    isValidIndex ? meetingList[currentIndex].id : null
  );

  const handlePrevious = () => {
    if (currentIndex !== 0) {
      dispatch({ type: "PREVIOUS_PROJECT" });
      sessionStorage.setItem(
        "lastSeenProject",
        JSON.stringify({ ...meetingProject, index: currentIndex })
      );
    }
  };

  const handleNext = () => {
    if (meetingList && currentIndex < meetingList.length - 1) {
      dispatch({ type: "NEXT_PROJECT" });
      sessionStorage.setItem(
        "lastSeenProject",
        JSON.stringify({ ...meetingProject, index: currentIndex })
      );
    }
  };

  return {
    handlePrevious,
    handleNext,
    currentIndex,
    isMeetingListLoading,
    isMeetingProjectLoading,
    meetingProject,
  };
};
