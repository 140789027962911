import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./routes/HomePage";
import SignInPage from "./routes/SignInPage";
import SignUpPage from "./routes/SignUpPage";

import RequireAuth from "./components/RequireAuth";
import { AuthProvider } from "./contexts/AuthContext";
import ActivitiesPage from "./routes/ActivitiesPage";
import AdminPanelPage from "./routes/admin/AdminPanelPage";
import TimeManagementPage from "./routes/admin/TimeManagementPage.jsx";
import ComissionsPage from "./routes/Comissions";
import ErrorPage from "./routes/ErrorPage";
import FinancesManagementPage from "./routes/FinancesManagementPage.jsx";
import ManagementPage from "./routes/ManagementPage";
import MeetingPage from "./routes/MeetingPage.jsx";
import StagesPage from "./routes/StagesPage";
import UnauthorizedPage from "./routes/UnauthorizedPage";
import NewMeetingPage from "./routes/meeting/page.jsx";

const ROLES = {
  User: 5050,
  Admin: 2019,
};

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/signIn" element={<SignInPage />} />
          <Route path="/signUp" element={<SignUpPage />} />
          <Route path="*" element={<ErrorPage />} />
        
          <Route
            element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Admin]} />}
          >
            <Route exact path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/finances" element={<FinancesManagementPage />} />
            <Route path="/activities" element={<ActivitiesPage />} />
            <Route path="/manage" element={<ManagementPage />} />
            <Route path="/stages" element={<StagesPage />} />
            <Route path="/comissions" element={<ComissionsPage />} />
            <Route path="/meeting" element={<NewMeetingPage />} />
            <Route path="/unauthorized" element={<UnauthorizedPage />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="/admin-panel" element={<AdminPanelPage />} />
            <Route path="/time-management" element={<TimeManagementPage />} />
          </Route>

          {/* <Route exact path="/counter" element={<CounterPage />} /> */}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
