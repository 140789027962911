import { api } from "../api";

export const getPricesByTableId = async (tableId: number) => {
  let url = `/admin/prices/${tableId}`;
  return await api.get(url);
};

export const updatePriceById = async (id: number, price: number) => {
  let url = `/admin/prices/${id}`;
  return await api.put(url, { price });
};
