import { useTheme } from "@emotion/react";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { ApiDataContext } from "../../../contexts/ApiDataContext";
import { useFormField } from "../../../hooks/forms/FormHooks";
import { useCheckBox, useManagementTextField } from "../../../hooks/forms/ManagementHooks";
import { useDatePicker } from "../../../hooks/forms/useDatePicker";
import { commonUpdateProject } from "../../../services/api/api";
import DatePicker from "../../DatePicker";
import CustomAutoComplete from "../../autoCompletes/CustomAutoComplete";
import ProvidencesAutoComplete from "../../autoCompletes/ProvidencesAutoComplete";
import StagesAutoComplete from "../../autoCompletes/StagesAutoComplete";
import CustomLoadingButton from "../../button/CustomLoadingButton";
import CustomTextField from "../../textFields/CustomTextField";
import CustomAccordion from "../../shared/CustomAccordion";
import { useEditProjectContext } from "./context/EditProjectContext";

export default function CommonEdit({
  projectData,
  isDisabled,
  setDisabled,
  initializeLoadingToast,
  updateLoadingToast,
}) {
  const { apiData } = useContext(ApiDataContext);
  const theme = useTheme();

  const queryClient = useQueryClient();
  const [requestLoading, setRequestLoading] = useState(false);

  const handleUpdateClick = async () => {
    let updateToastId;
    setRequestLoading(true);
    setDisabled(true);
    try {
      updateToastId = initializeLoadingToast({
        text: `Atualizando Informações Complementares...`,
      });

      const { status } = await commonUpdateProject(
        projectData.id,
        projectName.value,
        projectData?.stagesHistory[0]?.id,
        projectData?.providencesHistory[0]?.id,
        stage?.value?.id,
        providence?.value?.id,
        deadline.value,
        condition.value?.id,
        note.value,
        sentDate.value,
        secondSentDate.value,
        approvalDate.value,
        inspectionDate.value,
        finishDate.value
      );

      if (status === 200) {
        updateLoadingToast({
          id: updateToastId,
          text: `Informações Complementares atualizadas com sucesso`,
          type: "success",
        });

        queryClient.invalidateQueries({ queryKey: ["userProvidences"] });
        queryClient.invalidateQueries({ queryKey: ["homeBaseQuery"] });
        queryClient.invalidateQueries({ queryKey: ["filteredProjectsQuery"] });
      }
    } catch (error) {
      updateLoadingToast({
        id: updateToastId,
        text:
          error.response.data.message ??
          `Erro ao Atualizar Informações Complementares, tente novamente`,
        type: "error",
      });
    } finally {
      setRequestLoading(false);
      setDisabled(false);
    }
  };

  const { reset: resetProjectName, ...projectName } = useManagementTextField(projectData?.name);

  const { reset: resetStage, ...stage } = useFormField(
    "autocompleteChangeInitialValue",
    projectData?.stagesHistory[0]?.stages,
    false
  );

  const { reset: resetProvidence, ...providence } = useFormField(
    "autocompleteChangeInitialValue",
    projectData?.providencesHistory[0]?.providences,
    false
  );

  const { reset: resetCondition, ...condition } = useFormField(
    "autocompleteChangeInitialValue",
    projectData?.conditions,
    false
  );

  const { reset: resetDeadline, ...deadline } = useDatePicker(projectData?.dueDate);

  const { reset: resetNote, ...note } = useManagementTextField(projectData?.stagesHistory[0]?.note);

  const { reset: resetSecondSent, ...secondSent } = useCheckBox(
    Boolean(projectData?.secondSentDate)
  );

  const { reset: resetSentDate, ...sentDate } = useDatePicker(projectData?.firstSentDate);
  const { reset: resetSecondSentDate, ...secondSentDate } = useDatePicker(
    projectData?.secondSentDate
  );

  const { reset: resetApprovalDate, ...approvalDate } = useDatePicker(projectData?.approvalDate);

  const { reset: resetInspectionDate, ...inspectionDate } = useDatePicker(
    projectData?.inspectionDate
  );

  const { reset: resetFinishDate, ...finishDate } = useDatePicker(projectData?.finishDate);

  const { accordionsState, updateAccordionState } = useEditProjectContext();
  const handleChange = (e, isExpanded) => {
    updateAccordionState("commonEditOpen", isExpanded);
  };

  return (
    <CustomAccordion
      expanded={accordionsState.commonEditOpen}
      onChange={handleChange}
      title={"Informações Complementares"}
      height={420}
      actions={
        <CustomLoadingButton
          buttonText="Atualizar"
          buttonLoadingText="Atualizando..."
          sx={{ width: "17%", alignSelf: "flex-end" }}
          disabled={isDisabled}
          handleButtonClick={handleUpdateClick}
          requestLoading={requestLoading}
        />
      }
    >
      {projectData ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "45%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <CustomTextField
              params={projectName}
              disabled={isDisabled}
              label="Nome do Cliente"
              errorLabel="Nome Incorreto"
              maxLength={50}
              margin={0}
            />
            <StagesAutoComplete
              disabled={isDisabled}
              params={stage}
              options={apiData.stages}
              label="Etapa"
              width="100%"
            />
            <ProvidencesAutoComplete
              disabled={isDisabled}
              params={providence}
              options={apiData.providences}
              label="Providência"
              width="100%"
            />
            <DatePicker disabled={isDisabled} width="100%" label="Prazo Máximo" params={deadline} />
            <CustomAutoComplete
              disabled={isDisabled}
              params={condition}
              options={apiData.conditions}
              label="Situação"
              width="100%"
              margin={0}
            />
            <CustomTextField
              params={note}
              disabled={isDisabled}
              label="Observação"
              errorLabel="Observação Incorreta"
              maxLength={200}
            />
          </Box>
          <Box
            sx={{
              height: "80%",
              width: "45%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <DatePicker
              disabled={isDisabled}
              width="100%"
              label="Data de Envio"
              params={sentDate}
            />
            <Box display="flex" sx={{ justifyContent: "space-between" }}>
              <FormControlLabel
                control={<Checkbox {...secondSent} disabled={sentDate.value === ""} />}
                label="Possui Segundo envio?"
                labelPlacement="start"
              />
              <DatePicker
                disabled={isDisabled || !secondSent.checked || sentDate.value === ""}
                width="40%"
                label="Data"
                params={secondSentDate}
              />
            </Box>
            <DatePicker
              disabled={isDisabled || sentDate.value === ""}
              width="100%"
              label="Data de Parecer de Acesso"
              params={approvalDate}
            />
            <DatePicker
              disabled={isDisabled || approvalDate.value === ""}
              width="100%"
              label="Data de Vistoria"
              params={inspectionDate}
            />
            <DatePicker
              disabled={isDisabled || approvalDate.value === "" || inspectionDate.value === ""}
              width="100%"
              label="Data de Homologação"
              params={finishDate}
            />
          </Box>
        </Box>
      ) : null}
    </CustomAccordion>
  );
}
