import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFinancesProjects } from "../../../hooks/api/financesHooks";
import { formatDate } from "../../../services/date";
import { changeFinancePage } from "../../../store/ducks/finances-page-reducer";
import { FormatMoneyTypography, FormatPercentageTypography } from "components/format/Formats";

export default function FinancesProjects({ filters }) {
  const page = useSelector((state) => state.financesPage.value);
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const paymentsQuery = useFinancesProjects(filters, { page, size: rowsPerPage });

  const headCells = [
    { id: "projectNumber", label: "Projeto" },
    { id: "projectName", label: "Nome" },
    { id: "createTime", label: "Criação" },
    { id: "clientName", label: "Integrador" },
    { id: "stageName", label: "Etapa" },
    { id: "projectPrice", label: "Valor Projeto" },
    { id: "paidValue", label: "Valor Pago" },
  ];

  const handleChangePage = (event, newPage) => {
    dispatch(changeFinancePage(newPage));
  };

  if (paymentsQuery.isLoading) {
    return <CircularProgress />;
  }

  if (paymentsQuery.isError) {
    return <div>Error loading payments...</div>;
  }

  const payments = paymentsQuery.data?.items || [];
  const totalItems = paymentsQuery.data?.pagination.totalItems;

  return (
    <Paper sx={{ mt: 5 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell, i) => (
                <TableCell key={i}>{headCell.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((item) => {
              const { paidValue, projectPrice } = item;

              const paidPercentage = projectPrice
                ? paidValue
                  ? (paidValue / projectPrice) * 100
                  : 0
                : null;

              const color =
                paidPercentage !== null
                  ? paidPercentage === 100
                    ? "#00a04e"
                    : paidPercentage >= 50
                    ? "#cd9100"
                    : "#ff474c"
                  : "#abacb0";

              return (
                <TableRow key={item.projectId}>
                  <TableCell>{item.projectNumber}</TableCell>
                  <TableCell>{item.projectName}</TableCell>
                  <TableCell>{item.createTime ? formatDate(item.createTime) : ""}</TableCell>
                  <TableCell>{item.clientName}</TableCell>
                  <TableCell>{item.stageName}</TableCell>
                  <TableCell>
                    <FormatMoneyTypography
                      value={item.projectPrice ?? 0}
                    />
                  </TableCell>
                  <TableCell sx={{ maxWidth: 100, textAlign: "center" }}>
                    {projectPrice ? (
                      <Box
                        sx={{
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <FormatMoneyTypography value={item.paidValue ?? 0} sx={{ fontWeight: "bold" }} />
                        <FormatPercentageTypography value={paidPercentage} sx={{ color }} />
                      </Box>
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        labelRowsPerPage="Registros por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        count={totalItems}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5]}
        onRowsPerPageChange={() => {}}
      />
    </Paper>
  );
}
