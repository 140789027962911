import { useQuery } from "react-query";
import { getMeetingList, getMeetingProject } from "../../services/api/api";

const fetchMeetingList = async () => {
  const { data } = await getMeetingList();
  return data;
};

const fetchMeetingProject = async (id) => {
  const { data } = await getMeetingProject(id);
  return data;
};

export const useFetchMeetingList = () => {
  const { isLoading: isMeetingListLoading, data: meetingList } = useQuery(
    "meetingList",
    () => fetchMeetingList(),
    {
      retry: 5,
      staleTime: "infinity",
    }
  );

  return {
    isMeetingListLoading,
    meetingList,
  };
};

export const useFetchMeetingProject = (id) => {
  const { isLoading: isMeetingProjectLoading, data: meetingProject } = useQuery(
    `meetingProject-${id}`,
    () => fetchMeetingProject(id),
    {
      enabled: !!id,
    }
  );

  return {
    isMeetingProjectLoading,
    meetingProject,
  };
};
