import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box, Button, Chip } from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { ApiDataContext } from "../../../contexts/ApiDataContext";
import useDialog from "../../../hooks/dialog/useDialog";
import { createDateFromString, formatDate } from "../../../services/date";
import { changePage } from "../../../store/ducks/payment-page-reducer";
import SearchInput from "../../textFields/SearchInput";
import FiltersDialog from "./F-FiltersDialog";
import useMultipleAutocomplete from "hooks/forms/useMultipleAutocomplete";

export default function Filters({ filters, applyFilters, clearFilter }) {
  const { handleClickOpen, handleClickClose, open } = useDialog();
  const { apiData } = useContext(ApiDataContext);
  const dispatch = useDispatch();

  const restartPage = () => {
    dispatch(changePage(0));
  };

  const [range, setRange] = useState([
    {
      startDate: filters.startDate ? createDateFromString(filters.startDate) : new Date(),
      endDate: filters.endDate ? createDateFromString(filters.endDate) : new Date(),
      key: "selection",
    },
  ]);

  const handleRangeChange = (item) => {
    setRange([item.selection]);
  };

  const clearRange = () => {
    setRange([
      {
        startDate: "",
        endDate: "",
        key: "selection",
      },
    ]);
  };

  const clearStartDate = () => {
    setRange((prevRange) => [
      {
        ...prevRange[0],
        startDate: "", // Clear the start date
      },
    ]);
  };

  const clearEndDate = () => {
    setRange((prevRange) => [
      {
        ...prevRange[0],
        endDate: "", // Clear the end date
      },
    ]);
  };

  const [selectedClient, setSelectedClient] = useState(
    filters.clientId
      ? apiData.clients.find((client) => client.id === parseInt(filters.clientId))
      : []
  );

  const handleClientChange = async (event, newValue) => {
    setSelectedClient(newValue);
  };

  const clearClient = () => {
    setSelectedClient("");
  };

  const client = {
    value: selectedClient,
    onChange: handleClientChange,
  };

  const [search, setSearch] = useState(filters.searchStr ?? "");
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const {
    isChecked: isStageChecked,
    selected: selectedStage,
    handleSelect,
    setSelected: setSelectedStage,
  } = useMultipleAutocomplete(filters.stage, apiData.stages, "id");

  const clearStage = () => {
    setSelectedStage([]);
  };

  const stage = {
    value: selectedStage,
    onChange: handleSelect,
  };

  const handleApply = () => {
    applyFilters({
      stage: stage.value ? stage.value.map((s) => s.id).join(",") : "",
      clientId: client.value ? client.value.id : "",
      startDate: range[0]?.startDate ? format(range[0].startDate, "yyyy-MM-dd") : "",
      endDate: range[0]?.endDate ? format(range[0].endDate, "yyyy-MM-dd") : "",
      search: search ?? "",
    });
    restartPage();
    handleClickClose();
  };

  const handleClearFilters = () => {
    restartPage();
    clearClient();
    clearRange();
    clearStage();
    clearFilter("clientId");
    clearFilter("startDate");
    clearFilter("endDate");
    clearFilter("stage");
  };


  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection={"column"}
        width="100%"
        justifyContent={"space-between"}
        gap={1}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 2,
            justifyContent: "space-between",
          }}
        >
          <SearchInput
            value={search}
            onChange={handleSearchChange}
            placeholder="Pesquise por nome ou número"
            onSubmit={handleApply}
            onClear={() => {
              setSearch("");
              clearFilter("search");
            }}
          />

          <Button
            sx={{ width: "15%", borderRadius: 10 }}
            variant="contained"
            endIcon={<FilterAltIcon />}
            onClick={handleClickOpen}
          >
            Filtros
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          {/* Show active filters */}
          {filters.clientId && (
            <Chip
              label={`Cliente: ${client?.value?.name ?? ""}`}
              onDelete={() => {
                clearClient();
                restartPage();
                clearFilter("clientId");
              }}
            />
          )}
          {filters.stage && (
            <Chip
              sx={{
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              label={`Etapa${stage?.value.length > 1 ? "s: " : ": "} ${
                stage?.value?.map((s) => s.stageName).join(", ") ?? ""
              }`}
              onDelete={() => {
                clearStage();
                restartPage();
                clearFilter("stage");
              }}
            />
          )}
          {filters.startDate && (
            <Chip
              label={`Início: ${formatDate(filters.startDate)}`}
              onDelete={() => {
                clearStartDate();
                restartPage();
                clearFilter("startDate");
              }}
            />
          )}
          {filters.endDate && (
            <Chip
              label={`Fim: ${formatDate(filters.endDate)}`}
              onDelete={() => {
                clearEndDate();
                restartPage();
                clearFilter("endDate");
              }}
            />
          )}
        </Box>
      </Box>

      <FiltersDialog
        open={open}
        onClose={handleClickClose}
        client={client}
        clientsOptions={apiData.clients}
        stage={stage}
        stageOptions={apiData.stages}
        isStageChecked={isStageChecked}
        range={range}
        handleRangeChange={handleRangeChange}
        handleApply={handleApply}
        handleClearFilters={handleClearFilters}
      />
    </React.Fragment>
  );
}
