import { Box, Divider } from "@mui/material";
import React from "react";
import SummaryComponent from "./SummaryComponent";

export default function Summary({ data, isLoading, width = "75%", compWidth = "auto" }) {
  if (isLoading) return <div>Carregando Resumo...</div>;

  return (
    <Box
      display="flex"
      width={width}
      sx={{ justifyContent: "space-between", mt: 2, flexWrap: "wrap", rowGap: 2}}
    >
      {data.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <SummaryComponent data={item} width={compWidth} />
            {/* {index !== data.length - 1 ? ( */}
              <Divider orientation="vertical" variant="middle" flexItem />
            {/* ) : null} */}
          </React.Fragment>
        );
      })}

      {/* {data ? <div>{JSON.stringify(data)}</div> : <div>Nenhum dado encontrado.</div>} */}
    </Box>
  );
}
