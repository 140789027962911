import ArchiveIcon from "@mui/icons-material/Archive";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CodeIcon from "@mui/icons-material/Code";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import DownloadIcon from "@mui/icons-material/Download";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Box, Breadcrumbs, CircularProgress, IconButton, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useDialog from "../../hooks/dialog/useDialog";
import { useManagementTextField } from "../../hooks/forms/ManagementHooks";
import {
  createDefaultFolders,
  createFolder,
  deleteFile,
  listFiles,
  uploadFile,
} from "../../services/api/api";
import CustomLoadingButton from "../button/CustomLoadingButton";
import CustomContentDialog from "../dialog/CustomContentDialog";
import CustomDialog from "../dialog/CustomDialog";
import CustomTextField from "../textFields/CustomTextField";
import "./FileManager.css";
import PdfViewer from "./PdfViwer";
import CustomAccordion from "../shared/CustomAccordion";
import { useEditProjectContext } from "components/management/project/context/EditProjectContext";

const FileManager = ({ rootDirectory }) => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [createDefaultFoldersLoading, setCreateDefaultFoldersLoading] = useState(false);
  const [createFolderLoading, setCreateFolderLoading] = useState(false);
  const [previewFile, setPreviewFile] = useState({ name: "", url: "", type: "" });
  const [currentPath, setCurrentPath] = useState(rootDirectory || ""); // Caminho atual
  const [cache, setCache] = useState({}); // Cache para os arquivos carregados
  const [dragOver, setDragOver] = useState(false);
  const [fileId, setFileId] = useState(null);

  const fetchFiles = async (path = "", invalidateCache = false) => {
    if (!invalidateCache && cache[path]) {
      // Se os dados estiverem no cache, usa-os
      setFiles(cache[path]);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const response = await listFiles(path);
      if (Array.isArray(response)) {
        setFiles(response);
        setCache((prevCache) => ({ ...prevCache, [path]: response })); // Salva no cache
      } else {
        setError("Erro ao carregar arquivos: Diretório Inexistente");
      }
    } catch (err) {
      let erMsg = "Erro ao carregar arquivos.";
      try {
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data.error === "string" &&
          err.response.data.error.includes("not be found")
        ) {
          erMsg = "Diretório Inexistente.";
        }
      } catch (e) {
        console.error("Erro ao verificar a mensagem de erro:", e);
      }
      console.error("Erro ao buscar arquivos:", err);
      setError(erMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFiles(currentPath); // Carrega arquivos do caminho atual
  }, [currentPath, cache]);
  const [uploading, setUploading] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(true); // Define o estado para mostrar a mensagem
    event.currentTarget.classList.add("drag-over");
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(false); // Remove a mensagem de "Solte os arquivos aqui"
    event.currentTarget.classList.remove("drag-over");
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(false); // Remove a mensagem de "Solte os arquivos aqui"
    event.currentTarget.classList.remove("drag-over");

    const files = Array.from(event.dataTransfer.files);
    // Lógica para processar os arquivos, como fazer o upload
    handleFileUpload(files);
  };

  const handleFileUpload = async (uploadfiles) => {
    setUploading(true);
    try {
      // Use `Promise.all` para processar o upload de múltiplos arquivos simultaneamente
      const uploadPromises = uploadfiles.map(
        (file) => uploadFile(currentPath, file.name, file) // Faz o upload de cada arquivo
      );

      // Aguarda todos os uploads serem concluídos
      const results = await Promise.all(uploadPromises);

      // Atualize o estado com os arquivos enviados, assumindo que `results` é um array de objetos de arquivos
      setFiles((prevFiles) => [...prevFiles, ...results]);
      setCache((prevCache) => ({
        ...prevCache,
        [currentPath]: [...prevCache[currentPath], ...results].sort((a, b) =>
          a.name.localeCompare(b.name, "pt-BR")
        ), // Ordena os arquivos por nome
      })); // Atualiza o cache
    } catch (error) {
      console.error("Erro ao fazer upload:", error);
    } finally {
      setUploading(false);
    }
  };

  const handleDownload = (url) => {
    const anchor = document.createElement("a");
    anchor.href = url;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleDelete = async (id) => {
    try {
      const { status } = await deleteFile(id);

      if (status === 200) {
        setFiles(files.filter((file) => file.id !== id));
        setCache((prevCache) => ({
          ...prevCache,
          [currentPath]: files.filter((file) => file.id !== id),
        })); // Atualiza o cache
      }
    } catch (err) {
      console.error("Erro ao deletar arquivo:", err);
      setError("Erro ao deletar arquivo.");
    } finally {
      setFileId(null);
      handleDeleteClose();
    }
  };

  const handleOpenFolder = (name) => {
    setCurrentPath((prevPath) => {
      return `${prevPath}/${name}`;
    });
  };

  const handleBack = () => {
    const parentPath = currentPath.substring(0, currentPath.lastIndexOf("/"));
    setCurrentPath(parentPath);
  };

  const getFilePreviewType = (extension) => {
    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return "image";
      case "mp4":
      case "mov":
      case "avi":
        return "video";
      case "mp3":
      case "wav":
      case "ogg":
        return "audio";
      case "pdf":
        return "pdf";
      case "txt":
      case "md":
        return "text";
      default:
        return "unknown";
    }
  };

  const handlePreviewFile = async (file) => {
    const extension = file.name.split(".").pop().toLowerCase();
    const fileType = getFilePreviewType(extension);
    setPreviewFile({ name: file.name, url: file.downloadUrl, type: fileType });
    if (fileType === "unknown") {
      handleDownloadOpen();
      return;
    }
    handlePreviewOpen();
  };

  const getIconByFileType = (fileName, isFolder) => {
    if (isFolder) return <FolderIcon />; // Ícone de pasta para pastas

    const extension = fileName.split(".").pop().toLowerCase();

    switch (extension) {
      case "pdf":
        return <PictureAsPdfIcon />;
      case "doc":
      case "docx":
        return <DescriptionIcon />;
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return <ImageIcon />;
      case "zip":
      case "rar":
        return <ArchiveIcon />;
      case "dwg":
      case "dxf": // Extensões para arquivos de AutoCAD
        return <CodeIcon />;
      case "cdr": // Extensão para arquivos do CorelDRAW
        return <CodeIcon />;
      default:
        return <InsertDriveFileIcon />; // Ícone genérico para arquivos desconhecidos
    }
  };

  const handleCreateDefaultFolders = async (projectNumber) => {
    try {
      setCreateDefaultFoldersLoading(true);
      const { status } = await createDefaultFolders(projectNumber);

      if (status === 200) {
        setError(null);
        setCreateDefaultFoldersLoading(false);
        await fetchFiles(currentPath);
      }
    } catch (err) {
      // console.error("Erro ao deletar arquivo:", err);
      // setError("Erro ao deletar arquivo.");
    } finally {
      setCreateDefaultFoldersLoading(false);
    }
  };

  const folderName = useManagementTextField("");

  const handleCreateFolder = async () => {
    try {
      setCreateFolderLoading(true);
      const { status } = await createFolder(`${currentPath}/${folderName.value}`);

      if (status === 200) {
        setError(null);
        setCreateFolderLoading(false);
        await fetchFiles(currentPath, true);
      }
    } catch (err) {
      // console.error("Erro ao deletar arquivo:", err);
      // setError("Erro ao deletar arquivo.");
    } finally {
      setCreateFolderLoading(false);
      handleNewFolderClose();
    }
  };

  const {
    open: deleteOpen,
    handleClickOpen: handleDeleteOpen,
    handleClickClose: handleDeleteClose,
  } = useDialog();

  const {
    open: downloadOpen,
    handleClickOpen: handleDownloadOpen,
    handleClickClose: handleDownloadClose,
  } = useDialog();

  const {
    open: previewOpen,
    handleClickOpen: handlePreviewOpen,
    handleClickClose: handlePreviewClose,
  } = useDialog();

  const {
    open: newFolderOpen,
    handleClickOpen: handleNewFolderOpen,
    handleClickClose: handleNewFolderClose,
  } = useDialog();

  const { accordionsState, updateAccordionState } = useEditProjectContext();
  const handleChange = (e, isExpanded) => {
    updateAccordionState("fileManagerOpen", isExpanded);
  };

  const defaultFoldersButton = (
    <CustomLoadingButton
      buttonText="Criar Pastas Padrão"
      buttonLoadingText="Criando..."
      requestLoading={createDefaultFoldersLoading}
      disabled={createDefaultFoldersLoading}
      handleButtonClick={() => handleCreateDefaultFolders(currentPath)}
    />
  );

  return (
    <CustomAccordion
      expanded={accordionsState.fileManagerOpen}
      onChange={handleChange}
      title="Gerenciador de Arquivos"
      height={420}
      titleActions={
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            handleNewFolderOpen();
          }}
        >
          <CreateNewFolderIcon color="secondary" />
        </IconButton>
      }
    >
      <CustomDialog
        open={deleteOpen}
        handleClickClose={handleDeleteClose}
        handleConfirmationClick={() => handleDelete(fileId)}
        title="Excluir arquivo?"
        contentText="O arquivo não poderá ser recuperado"
        confirmationClickText="Excluir"
        width={350}
        height={60}
      />

      <CustomDialog
        open={downloadOpen}
        handleClickClose={handleDownloadClose}
        handleConfirmationClick={() => {
          handleDownload(previewFile.url);
          handleDownloadClose();
        }}
        title="Baixar arquivo?"
        contentText="O tipo de arquivo selecionado não é suportado para visualização."
        confirmationClickText="Baixar"
      />

      <CustomContentDialog
        width={1000}
        height="auto"
        open={previewOpen}
        handleClickClose={handlePreviewClose}
        handleConfirmationClick={() => {
          handleDownload(previewFile.url);
          handlePreviewClose();
        }}
        title={previewFile.name}
        confirmationClickText="Baixar"
        content={
          previewFile.type === "image" ? (
            <img
              src={previewFile.url}
              alt="Preview"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          ) : previewFile.type === "video" ? (
            <video src={previewFile.url} controls style={{ width: "100%", height: "100%" }} />
          ) : previewFile.type === "audio" ? (
            <audio src={previewFile.url} controls style={{ width: "100%" }} />
          ) : previewFile.type === "pdf" ? (
            <PdfViewer pdfUrl={previewFile.url} />
          ) : previewFile.type === "text" ? (
            <iframe
              src={`data:text/plain;charset=utf-8,${encodeURIComponent(previewFile.url)}`}
              title="Preview"
              style={{ width: "100%", height: "100%" }}
            />
          ) : (
            <iframe
              src={previewFile.url}
              title="Preview"
              style={{ width: "100%", height: "100%" }}
            />
          )
        }
      />

      <CustomContentDialog
        open={newFolderOpen}
        handleClickClose={handleNewFolderClose}
        handleConfirmationClick={() => handleCreateFolder()}
        title="Criar nova pasta"
        confirmationClickText="Criar"
        requestLoading={createFolderLoading}
        requestLoadingText="Criando..."
        content={
          <CustomTextField
            params={folderName}
            label="Nome da pasta"
            maxLength={30}
            width="100%"
            required={true}
            errorLabel="Nome da pasta é obrigatório"
            sx={{ mt: 0.7 }}
          />
        }
      />

      <div
        style={{ height: "100%" }}
        className="file-manager"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <ul className="file-list">
          <div style={{ display: "flex", alignItems: "center" }}>
            {currentPath !== rootDirectory && (
              <ArrowBackIcon
                style={{ cursor: "pointer", marginRight: "8px" }} // Espaço horizontal entre o ícone e o breadcrumbs
                onClick={handleBack}
              />
            )}
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<span style={{ margin: "0 2px" }}>/</span>} // Custom separator with minimal space
            >
              {currentPath
                .split("/")
                .filter(Boolean)
                .map((folder, index, arr) => (
                  <Link
                    key={folder}
                    onClick={() => {
                      const path = `/${arr.slice(0, index + 1).join("/")}`;
                      setCurrentPath(path);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {folder}
                  </Link>
                ))}
            </Breadcrumbs>
          </div>

          {loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : error ? (
            currentPath == rootDirectory ? (
              defaultFoldersButton
            ) : (
              <div className="empty-folder-message">{error}</div>
            )
          ) : files.length === 0 ? (
            currentPath == rootDirectory ? (
              defaultFoldersButton
            ) : (
              <div className="empty-folder-message">A pasta está vazia.</div>
            )
          ) : (
            files.map((file) => (
              <li key={file.id} className={file.type === "folder" ? "folder" : ""}>
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    maxWidth: "235px",
                    cursor: file.type === "folder" ? "pointer" : "default",
                  }}
                  onClick={
                    file.type === "folder"
                      ? () => handleOpenFolder(file.name)
                      : () => handlePreviewFile(file)
                  }
                >
                  <span
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    {getIconByFileType(file.name, file.type === "folder")}
                  </span>
                  <span className="file-name">{file.name}</span>
                </div>
                <div className="actions">
                  {file.type !== "folder" && (
                    <DownloadIcon
                      style={{ color: "blue", cursor: "pointer", marginLeft: "8px" }}
                      onClick={() => handleDownload(file.downloadUrl)}
                    />
                  )}
                  <DeleteIcon
                    style={{ color: "red", cursor: "pointer", marginLeft: "8px" }}
                    onClick={() => {
                      setFileId(file.id);
                      handleDeleteOpen();
                    }}
                  />
                </div>
              </li>
            ))
          )}
        </ul>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {dragOver && (
            <React.Fragment>
              <CloudUploadIcon />
              <Typography sx={{ textAlign: "center" }}>
                Solte o(s) arquivo(s) aqui <br /> para realizar o upload
              </Typography>
            </React.Fragment>
          )}
          {uploading && <CircularProgress />}
        </Box>
      </div>
    </CustomAccordion>
  );
};

export default FileManager;
