import { Box } from "@mui/material";

import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { useDatePicker } from "../../../../hooks/forms/useDatePicker";
import { useErrorToast, useLoadingToast } from "../../../../hooks/toast/toasts";
import { sendReport } from "../../../../services/api/admin/adminReportApi";
import { formatDateToDatabase } from "../../../../services/date";
import CustomLoadingButton from "../../../button/CustomLoadingButton";
import DatePicker from "../../../DatePicker";

export default function ReportsManagement() {
  const [isLoading, setLoading] = useState(false);
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();
  const { initializeErrorToast } = useErrorToast();

  const date = useDatePicker(null);

  const handleClick = async () => {
    let createToastId;
    try {
      if (date.value === null || date.value === "") {
        initializeErrorToast({
          text: `Selecione a data para enviar o relatório`,
          options: {},
        });
        return;
      }

      setLoading(true);
      createToastId = initializeLoadingToast({
        text: `Enviando Relatório...`,
        options: {},
      });

      const formattedDate = formatDateToDatabase(new Date(date.value));

      await sendReport(formattedDate);

      updateLoadingToast({
        id: createToastId,
        text: `Relatório Enviado com sucesso`,
        type: "success",
      });
    } catch (error) {
      updateLoadingToast({
        id: createToastId,
        text: error.response.data.message ?? "Erro ao enviar relatório, tente novamente",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const buttonStyle = {
    alignSelf: "flex-end",
  };
  return (
    <Box width="100%">
      <ToastContainer />

      <Box
        display="flex"
        sx={{
          width: "400px",
          justifyContent: "space-between",
          flexDirection: "row",
          borderRadius: 2,
          p: 2,
          backgroundColor: "#D9D9D9",
          boxShadow: 3,
          mb: 1,
        }}
      >
        <DatePicker label={"Selecione a data"} params={date} disableFuture={true} width="45%" />

        <CustomLoadingButton
          buttonText="Relatório Financeiro"
          buttonLoadingText="Enviando..."
          sx={buttonStyle}
          disabled={isLoading || date == null || date == ""}
          handleButtonClick={handleClick}
          requestLoading={isLoading}
        />
      </Box>
    </Box>
  );
}
