import PostAddIcon from "@mui/icons-material/PostAdd";
import { Box, IconButton, Skeleton, styled } from "@mui/material";
import CustomAutoComplete from "components/autoCompletes/CustomAutoComplete";
import CustomContentDialog from "components/dialog/CustomContentDialog";
import CustomTextField from "components/textFields/CustomTextField";
import { ToastContainer } from "react-toastify";
import PricesTable from "./prices-table";
import { usePricesPanel } from "./usePricesPanel";

const RoundedIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  borderRadius: "50%",
  width: "48px",
  height: "48px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export default function PricesPanel() {
  const {
    open,
    handleClickClose,
    handleCreateNewTable,
    isLoading,
    tableName,
    pricesLoading,
    pricesTableData,
    table,
    handleClickOpen,
    handleDeleteTable
  } = usePricesPanel();

  return (
    <>
      <ToastContainer />
      <CustomContentDialog
        open={open}
        handleClickClose={handleClickClose}
        handleConfirmationClick={() => handleCreateNewTable()}
        title="Criar nova Tabela"
        confirmationClickText="Criar"
        requestLoadingText="Criando..."
        requestLoading={isLoading}
        content={
          <CustomTextField
            params={tableName}
            label="Nome da Tabela"
            maxLength={30}
            width="100%"
            required={true}
            sx={{ mt: 0.7 }}
          />
        }
      />

      {pricesLoading ? (
        <Skeleton variant="rounded" width="100%" height={50} />
      ) : (
        <Box
          display="flex"
          sx={{ alignItems: "center", mb: 2, width: "30%", justifyContent: "space-between" }}
        >
          {pricesTableData ? (
            <CustomAutoComplete
              margin={0}
              pt={0}
              params={table}
              options={pricesTableData}
              disabled={false}
              label="Tabelas de Preço"
              width="80%"
            />
          ) : null}
          <RoundedIconButton onClick={(e) => handleClickOpen()}>
            <PostAddIcon fontSize="large" />
          </RoundedIconButton>
        </Box>
      )}

      {table.value ? (
        <PricesTable width={"100%"} table={table.value} handleDeleteTable={handleDeleteTable} />
      ) : null}
    </>
  );
}
