import { useQuery } from "react-query";
import { getPayments, getPaymentsHistory, getSummary } from "../../services/api/api";

const fetchSummary = async (filters) => {
  const { data } = await getSummary(filters);
  return data;
};

const fetchPayments = async (filters) => {
  const { data } = await getPayments(filters);
  return data;
};

const fetchPaymentsHistory = async (projectId) => {
  const { data } = await getPaymentsHistory(projectId);
  return data;
};

export const useSummary = (filters) => {
  return useQuery(["summary", filters], () => fetchSummary(filters));
};

export const usePayments = (filters, { page = 0, size = 10 }) => {
  return useQuery(
    ["payments", filters, page, size],
    () => fetchPayments({ ...filters, page, size }),
    {
      keepPreviousData: true,
    }
  );
};

export const usePaymentsHistory = (projectId) => {
  const { isLoading: paymentsHistoryLoading, data: paymentsHistoryData } = useQuery(
    ["paymentsHistory", projectId],
    () => fetchPaymentsHistory(projectId),
    {
      staleTime: 300000, //5 minutes to stale
      retry: 5,
    }
  );

  return {
    paymentsHistoryLoading,
    paymentsHistoryData
  }
};