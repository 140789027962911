import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { usePaymentsHistory } from "../../../hooks/api/chargesHooks";
import useDialog from "../../../hooks/dialog/useDialog";
import { useAutocomplete } from "../../../hooks/forms/FormHooks";
import { sendBillingPdf } from "../../../services/api/api";
import { formatDate } from "../../../services/date";
import CustomAutoComplete from "../../autoCompletes/CustomAutoComplete";
import CustomLoadingButton from "../../button/CustomLoadingButton";
import CustomContentDialog from "../../dialog/CustomContentDialog";
import { useQueryClient } from "react-query";
import CustomAccordion from "../../shared/CustomAccordion";
import { useEditProjectContext } from "./context/EditProjectContext";

const defaultBillingKinds = [{ name: "ENTRADA" }, { name: "VISTORIA" }, { name: "INTEGRAL" }];

const paymentKinds = {
  1: "Entrada",
  2: "Vistoria",
  3: "Integral",
};

const paymentStatus = {
  PAID: "Recebido",
  MARKED_AS_PAID: "Marcado c/ recebido",
  WAITING: "A receber",
  OVERDUE: "Atrasado",
  EXPIRED: "Expirado",
  CANCELLED: "Cancelado",
};

const paymentColors = {
  PAID: "#00a04e",
  MARKED_AS_PAID: "#71a846",
  WAITING: "#0094dd",
  OVERDUE: "#ffbc31",
  EXPIRED: "#abacb0",
  CANCELLED: "#ff474c",
};

const PaymentsHistory = ({
  projectId,
  isUniqueCharge,
  clearAllFields,
  isDisabled,
  setDisabled,
  initializeLoadingToast,
  updateLoadingToast,
}) => {
  const theme = useTheme();
  const { paymentsHistoryLoading, paymentsHistoryData } = usePaymentsHistory(projectId);
  const [billingKinds, setBillingKinds] = useState(defaultBillingKinds);
  const [isSendBillLoading, setIsSendBillLoading] = useState(false);
  const { reset: resetBillingKind, ...billingKind } = useAutocomplete();
  const queryClient = useQueryClient();

  useEffect(() => {
    let tempBillingKinds = defaultBillingKinds;

    // This Line of code assures that if the client is selected for full charges, only
    // the full charge option appears to him
    if (isUniqueCharge) {
      tempBillingKinds = tempBillingKinds.filter((item) => item.name === "INTEGRAL");
    }

    if (!paymentsHistoryLoading && paymentsHistoryData.length !== 0) {
      // This Line of code assures that, even if the client is selected for full charges,
      // if a two-way charge is already sent for him, the another one can be sent too.
      tempBillingKinds = defaultBillingKinds;

      // Iterate over the payments to determine the appropriate billing kinds
      paymentsHistoryData.forEach((p) => {
        if (p.paymentKindId === 3 && p.status && p.status !== "EXPIRED") {
          tempBillingKinds = [];
          return; // Exit the loop early if the condition is met
        } else if (p.paymentKindId === 2 && p.status && p.status !== "EXPIRED") {
          tempBillingKinds = tempBillingKinds.filter((item) => item.name === "ENTRADA");
        } else if (p.paymentKindId === 1 && p.status && p.status !== "EXPIRED") {
          tempBillingKinds = tempBillingKinds.filter((item) => item.name === "VISTORIA");
        }
      });
    }
    setBillingKinds(tempBillingKinds);
  }, [paymentsHistoryData, isUniqueCharge]);

  const headCells = [
    { id: "paymentKindId", label: "Cobrança" },
    { id: "chargeDate", label: "Emissão" },
    { id: "chargeValue", label: "Valor" },
    { id: "status", label: "Status" },
  ];

  const {
    open: billDialogOpen,
    handleClickOpen: handleBillDialogOpen,
    handleClickClose: handleBillDialogClose,
  } = useDialog();

  const dialogContent = (
    <CustomAutoComplete
      params={billingKind}
      options={billingKinds}
      disabled={isDisabled}
      label="Tipo de Pagamento"
      width="100%"
    />
  );

  const handleSendBillClick = async () => {
    let sendBillToastId;
    setIsSendBillLoading(true);
    setDisabled(true);
    try {
      handleBillDialogClose();
      sendBillToastId = initializeLoadingToast({
        text: `Enviando Cobrança...`,
      });

      const { status } = await sendBillingPdf(projectId, billingKind.value.name);

      if (status === 200) {
        clearAllFields();

        updateLoadingToast({
          id: sendBillToastId,
          text: `Cobrança em processo de envio ao integrador`,
          type: "success",
        });
        queryClient.invalidateQueries({ queryKey: ["paymentsHistory"] });
      } else {
        throw new Error();
      }
    } catch (error) {
      updateLoadingToast({
        id: sendBillToastId,
        text: error.response.data.message ?? `Erro ao enviar cobrança, tente novamente`,
        type: "error",
      });
    } finally {
      setIsSendBillLoading(false);
      setDisabled(false);
    }
  };

  const { accordionsState, updateAccordionState } = useEditProjectContext();
  const handleChange = (e, isExpanded) => {
    updateAccordionState("paymentOpen", isExpanded);
  };

  return (
    <CustomAccordion
      expanded={accordionsState.paymentOpen}
      onChange={handleChange}
      height={250}
      title="Histórico de Pagamentos"
      titleActions={
        <CustomLoadingButton
          color={theme.palette.info.main}
          hoverColor={theme.palette.info.hover}
          startIcon={<WhatsAppIcon />}
          buttonText="Boleto"
          buttonLoadingText="Gerando..."
          sx={{
            height: 30,
            width: "30%",
          }}
          disabled={isDisabled}
          handleButtonClick={handleBillDialogOpen}
          requestLoading={isSendBillLoading}
        />
      }
    >
      <CustomContentDialog
        open={billDialogOpen}
        handleClickClose={handleBillDialogClose}
        title="Enviar Boleto/Pix"
        content={dialogContent}
        requestLoading={isSendBillLoading}
        handleConfirmationClick={handleSendBillClick}
        confirmationClickText="Enviar"
        requestLoadingText="Enviando..."
        width={500}
        height={100}
      />

      {!paymentsHistoryLoading ? (
        paymentsHistoryData.length !== 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell, i) => (
                    <TableCell key={i}>{headCell.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {paymentsHistoryData.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell>{paymentKinds[item.paymentKindId]}</TableCell>
                    <TableCell>{item.chargeDate ? formatDate(item.chargeDate) : ""}</TableCell>
                    <TableCell>{`R$ ${item.chargeValue}`}</TableCell>
                    <TableCell sx={{ maxWidth: 100, textAlign: "center" }}>
                      <Box
                        sx={{
                          backgroundColor: paymentColors[item.status],
                          p: 0.4,
                          borderRadius: 1,
                          textAlign: "center",
                        }}
                      >
                        {paymentStatus[item.status]}
                      </Box>
                      {(item.status === "PAID" || item.status === "MARKED_AS_PAID") && (
                        <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                          {item.paymentDate ? formatDate(item.paymentDate) : ""}
                        </Typography>
                      )}
                      {item.status === "EXPIRE" && (
                        <Typography variant="caption">{formatDate(item.expireDate)}</Typography>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              fontWeight: "medium",
              fontSize: "1.1rem",
              opacity: 0.7,
              mt: 2,
            }}
          >
            Nenhuma cobrança cadastrada
          </Typography>
        )
      ) : (
        <CircularProgress />
      )}
    </CustomAccordion>
  );
};

export default PaymentsHistory;
