import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import SearchBar from "../components/activities/SearchBar";

import AppBarMenu from "../components/AppBarMenu";
import ActivitiesList from "../components/activities/ActivitiesList";
import { useFetchUserProvidences } from "../hooks/api/userProvidencesApiHooks";

//TODO: Fazer um contexto pra isso aqui, pelo amor de Deus
export default function ActivitiesPage() {
  const [isMyActivitiesMarked, setIsMyActivitiesMarked] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState([]);
  const [providencesFilter, setProvidencesFilter] = useState([]);
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  // useEffect(() => {
  //   (async () => {
  //     if (isMyActivitiesMarked) {
  //       setUserProvidencesIds(JSON.stringify(user.providences));
  //     } else {
  //       setUserProvidencesIds(null);
  //     }
  //   })();
  // }, [isMyActivitiesMarked, user]);

  const { userProvidencesLoading, userProvidencesData } = useFetchUserProvidences({
    userId: user.id,
    providencesFilter,
    searchValue,
    includeAllActivities: !isMyActivitiesMarked,
  });

  return (
    <AppBarMenu icons={null}>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 100,
              }}
            >
              <SearchBar
                setProvidencesFilter={setProvidencesFilter}
                providencesFilter={providencesFilter}
                data={userProvidencesData}
                isMyActivitiesMarked={isMyActivitiesMarked}
                setIsMyActivitiesMarked={setIsMyActivitiesMarked}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </Paper>
          </Grid>

          {userProvidencesLoading ? (
            <Box
              sx={{
                width: "100%",
                height: "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={80} />
            </Box>
          ) : (
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  minHeight: 600,
                }}
              >
                <ActivitiesList data={userProvidencesData} />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Container>
    </AppBarMenu>
  );
}
