import { api } from "../api";

const baseUrl = "/meeting";

export const getMeetingList = async () => {
  const url = `${baseUrl}/list`;
  return await api.get(url);
};

export const getMeetingProject = async (id) => {
  const url = `${baseUrl}/${id}`;
  return await api.get(url);
};

export const updateMeetingProject = async (
  projectId,
  projectName,
  clientId,
  powerDistributionCompanyId,
  engineerId,
  historyStageId,
  historyProvidenceId,
  stageId,
  providenceId,
  note,
  conditionId,
  deadline,
  power,
  price,
  sentDate,
  secondSentDate,
  approvalDate,
  inspectionDate,
  finishDate
) => {
  let url = `/meeting/${projectId}`;

  return await api.put(url, {
    projectName,
    clientId,
    powerDistributionCompanyId,
    engineerId,
    historyStageId,
    historyProvidenceId,
    stageId,
    providenceId,
    note,
    conditionId,
    deadline,
    power,
    price,
    sentDate,
    secondSentDate,
    approvalDate,
    inspectionDate,
    finishDate,
  });
};
