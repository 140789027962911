import { useQuery } from "react-query";
import { getUserProvidences } from "../../services/api/api";

const userProvidencesApi = {
  fetchUserProvidences: async ({
    providencesFilter,
    includeAllActivities,
    userId,
  }: Omit<useFetchUserActivitiesProps, "searchValue">) => {
    const { data } = await getUserProvidences({ providencesFilter, includeAllActivities, userId });
    return data;
  },
};

export type useFetchUserActivitiesProps = {
  userId?: number;
  providencesFilter?: number[];
  searchValue: any;
  includeAllActivities: boolean;
};

export const useFetchUserProvidences = ({
  userId,
  providencesFilter,
  searchValue,
  includeAllActivities = false,
}: useFetchUserActivitiesProps) => {
  const { isLoading: userProvidencesLoading, data: userProvidencesData } = useQuery(
    ["userProvidences", [providencesFilter, searchValue, includeAllActivities, userId]],
    () => userProvidencesApi.fetchUserProvidences({providencesFilter, includeAllActivities, userId}),
    {
      staleTime: 5000,
      retry: 5,
      select: (activities) => {
        if (searchValue.length != 0) {
          return activities.filter((activity) => activity.id === searchValue.id);
        }
        return activities;
      },
    }
  );

  return {
    userProvidencesLoading,
    userProvidencesData,
  };
};
