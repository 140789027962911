import ClearIcon from "@mui/icons-material/Clear";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Box, Button, useTheme } from "@mui/material";
import React, { useState } from "react";
import { attorneyUpdateProject } from "../../../../services/api/api";
import CustomLoadingButton from "../../../button/CustomLoadingButton";
import { FormatCNPJ, FormatCPF } from "../../../format/Formats";
import AddressForm from "../../../forms/address/AddressForm";
import CustomAccordion from "../../../shared/CustomAccordion";
import CustomTextField from "../../../textFields/CustomTextField";
import FormatedCustomTextField from "../../../textFields/FormatedCustomTextField";
import CustomRadioGroup from "../../CustomRadioGroup";
import { useEditProjectContext } from "../context/EditProjectContext";

export default function AttorneyForm({
  projectId,
  isDisabled,
  setDisabled,
  controller,
  addressController,
  isEditing = false,
  handleSendAttorney,
  isAttorneySending,
  initializeLoadingToast,
  updateLoadingToast,
}) {
  const theme = useTheme();

  const [requestLoading, setRequestLoading] = useState(false);

  const handleUpdateAttorney = async () => {
    let updateToastId;

    try {
      if (!controller.validateFields()) {
        setRequestLoading(true);
        setDisabled(true);
        updateToastId = initializeLoadingToast({
          text: `Atualizando Informações para Procuração...`,
        });

        const attorneyValues = controller.values();
        const npParams = {
          cpf: attorneyValues.cpf,
          rg: attorneyValues.rg,
          issuingBody: attorneyValues.issuingBody,
        };
        const leParams = {
          cnpj: attorneyValues.cnpj,
          repName: attorneyValues.repName,
          repNationality: attorneyValues.repNationality,
          repCpf: attorneyValues.cpf,
          repRg: attorneyValues.rg,
          repIssuingBody: attorneyValues.issuingBody,
        };

        const { status } = await attorneyUpdateProject(projectId, {
          clientType: attorneyValues.clientType,
          npParams,
          leParams,
          ...addressController.values(),
        });

        if (status === 200) {
          updateLoadingToast({
            id: updateToastId,
            text: `Informações para Procuração atualizadas com sucesso`,
            type: "success",
          });
        }
      } else {
        updateToastId = initializeLoadingToast({
          text: `Verifique os campos de procuração`,
          type: "info",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: updateToastId,
        text:
          error?.response?.data?.message ??
          `Erro ao atualizar Informações para procuração, tente novamente`,
        type: "error",
      });
    } finally {
      setRequestLoading(false);
      setDisabled(false);
    }
  };

  const titleActions = isEditing && (
    <CustomLoadingButton
      color={theme.palette.info.main}
      hoverColor={theme.palette.info.hover}
      startIcon={<PictureAsPdfIcon />}
      buttonText="Procuração"
      buttonLoadingText="Gerando..."
      sx={{
        width: "18%",
        mr: 3,
      }}
      disabled={isDisabled}
      handleButtonClick={handleSendAttorney}
      requestLoading={isAttorneySending}
    />
  );

  const actions = isEditing && (
    <CustomLoadingButton
      buttonText="Atualizar"
      buttonLoadingText="Atualizando..."
      sx={{
        width: "17%",
      }}
      disabled={isDisabled}
      handleButtonClick={handleUpdateAttorney}
      requestLoading={requestLoading}
    />
  );

  const { accordionsState, updateAccordionState } = useEditProjectContext();
  const handleChange = (e, isExpanded) => {
    updateAccordionState("attorneyDataOpen", isExpanded);
  };

  return (
    <CustomAccordion
      expanded={isEditing && accordionsState ? accordionsState.attorneyDataOpen : undefined}
      onChange={isEditing && accordionsState ? handleChange : undefined}
      title="Dados para Procuração"
      titleActions={titleActions}
      actions={actions}
    >
      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", mt: 2 }}>
        <CustomRadioGroup
          radioGroupParams={controller.clientType}
          label={"Tipo de cliente:"}
          radioValues={["PERSON", "ENTITY"]}
          radioLabels={["Pessoa Física", "Pessoa Jurídica"]}
        />

        <Button
          variant="outlined"
          color="error"
          sx={{
            height: 30,
            alignSelf: "flex-end",
          }}
          disabled={isDisabled}
          onClick={() => {
            controller.clearAllFields();
            addressController.clearAllFields();
          }}
          endIcon={<ClearIcon />}
        >
          Limpar
        </Button>
      </Box>

      <Box
        sx={{
          height: 400,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Box
          sx={{
            width: "48%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {controller?.clientType?.value === "ENTITY" && (
            <React.Fragment>
              <FormatedCustomTextField
                params={controller.cnpj}
                disabled={isDisabled}
                label="CNPJ"
                inputcomponent={FormatCNPJ}
              />
              <CustomTextField
                params={controller.repName}
                disabled={isDisabled}
                label="Nome - Representante Legal"
                errorLabel="Representante Legal Incorreto"
              />
              <CustomTextField
                params={controller.repNationality}
                disabled={isDisabled}
                label="Nacionalidade - Representante Legal"
              />
            </React.Fragment>
          )}
          <FormatedCustomTextField
            params={controller.cpf}
            disabled={isDisabled}
            label={controller?.clientType?.value === "ENTITY" ? "CPF - Representante Legal" : "CPF"}
            inputcomponent={FormatCPF}
          />
          <CustomTextField
            params={controller.rg}
            disabled={isDisabled}
            label={controller?.clientType?.value === "ENTITY" ? "RG - Representante Legal" : "RG"}
          />
          <CustomTextField
            params={controller.issuingBody}
            disabled={isDisabled}
            label={
              controller?.clientType?.value === "ENTITY"
                ? "Órgão Expedidor - Representante Legal"
                : "Órgão Expedidor"
            }
            errorLabel="Órgão Expedidor Incorreto"
          />
        </Box>
        <Box
          sx={{
            width: "48%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <AddressForm controller={addressController} required={false} />
        </Box>
      </Box>
    </CustomAccordion>
  );
}
